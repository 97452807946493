import React from 'react';
import { AxiosError } from "axios";

import ContextMenu/*, { IContextMenuOption }*/ from "src/components/ContextMenu/ContextMenu";
import { toast } from "src/components/Toast/ToastManager";

import { apiService } from "../../../services";
import type { CustomCellRendererProps } from 'ag-grid-react';

import styles from "./DiscountsTab.module.scss";

interface CustomDiscountCellRendererProps extends CustomCellRendererProps {
  getDiscounts(): void
}

export const ActionsRenderer = (props: CustomDiscountCellRendererProps) => {
  const deleteDiscount = () => {
    apiService.deleteEventDiscount(props.value.event._id, props.value.discount._id).then((response) => {
      toast.show({
        title: "Remove Discount",
        content: "Successfully removed " + props.value.discount.discount_code,
        duration: 10000,
        type: "success"
      });

      props.getDiscounts();
    }).catch((e: AxiosError) => {
      toast.show({
        title: "Remove Discount",
        content: "Unable to remove discount",
        duration: 10000,
        errorDetails: e,
        type: "fail"
      });
    });
  }

  return (<div className={styles.ActionRenderer}>
    <ContextMenu
      options={[
        { 
          label: "Delete Discount",
          onClick:() => {
            deleteDiscount();
          }
        }
      ]}
    />
  </div>);
};
