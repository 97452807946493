import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { AxiosError } from "axios";
import { IEventSummary } from "@eagerdog/interfaces";

import { apiService } from "src/services/api.service";

import { Modal, useModal } from "src/components/Modal/Modal";
import ContextMenu, { IContextMenuOption } from "src/components/ContextMenu/ContextMenu";
import SendMessage from "src/components/SendMessage/SendMessage";
import { download } from "src/components/Download/DownloadManager";
import { toast } from "src/components/Toast/ToastManager";

import type { ColDef } from "ag-grid-community";
import { themeQuartz } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

import { ActionsRenderer } from "./ActionsRenderer";

import styles from "./RegistrantsTab.module.scss";

interface IProps {
  event: IEventSummary
}

interface IRow {
  callName: string,
  armband: string,
  breed: string,
  show: string,
  showDate: string,
  registeredAt: string,
  actions: any
}

const RegistrantsTab: React.FC<IProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  const { isShown, toggle } = useModal();

  const menuActions:IContextMenuOption[] = [
    {
      label: "Export Registrants",
      onClick: () => {
        download.pushDownload({
          type: "armband",
          eventId: props.event._id
        });
      }
    }, {
      label: "Download Forms",
      onClick: () => {
        download.pushDownload({
          type: "print_registration",
          eventId: props.event._id
        });
      }
    }, {
      label: "Email Registrants",
      onClick: () => {
        toggle();
      }
    }
  ];

  const theme = themeQuartz.withParams({
    fontFamily: "Roboto",
    headerFontFamily: "Roboto",
    cellFontFamily: "Roboto"
  });

  const getRegistrants = useCallback((id: string) => {
    let query:any = {
      sort: [{
        attribute_name: "show_type",
        sort: "asc"
      }, {
        attribute_name: "created_at",
        sort: "asc"
      }],
    };

    apiService.getEventShowRegistrations(id, query).then((response) => {
      let rows:IRow[] = [];

      for (let i in response) {
        let row:any = response[i];

        rows.push({
          callName: row.dog.call_name + " (" + row.dog.registered_name + ")",
          armband: row.arm_number,
          breed: row.dog.breed,
          show: row.show_type + " " + row.show_name,
          showDate: moment(row.show_date).format("MM/DD/YY"),
          registeredAt: moment(row.created_at).format("MM/DD/YY hh:mm:ssA"),
          actions: {
            registration: row,
            event: props.event
          }
        });
      }

      setRowData(rows);
    });
  }, [props.event]);

  let colDefs:ColDef<IRow>[] = [
    { headerName: "Call Name (Reg. Name)", field: "callName", minWidth: 150, filter:true },
    { field: "armband", minWidth: 120, maxWidth: 120, cellDataType: "text", filter:true },
    { field: "breed", minWidth: 150, filter:true },
    { field: "show", minWidth: 150, filter:true },
    { field: "showDate", minWidth: 150, filter:true },
    { field: "registeredAt", minWidth: 150, filter:true },
    {
      headerName: "",
      field: "actions",
      maxWidth: 50,
      minWidth: 50,
      cellRenderer: ActionsRenderer,
      pinned: "right",
      cellRendererParams: {
        getRegistrants: () => { getRegistrants(props.event._id); }
      },
      resizable: false,
      cellStyle: {
        "overflow": "visible",
        "zIndex": "2"
      }
    }
  ];

  const [rowData, setRowData] = useState<IRow[]>([]);

  const defaultColDef: ColDef = {
    flex: 1
  };

  const emailRegistrants = (message:string, attach_final_confirmation: boolean) => {
    apiService.requestSendMessageEmailForEvent(props.event._id, message, attach_final_confirmation).then((response) => {
      toast.show({
        title: "Email Results",
        content: "Your message has been sent to all current registrants.",
        duration: 10000,
        type: "success"
      });  
    }).catch((e: AxiosError) => {
      toast.show({
        title: "Email Results",
        content: "Unable to email results",
        duration: 10000,
        errorDetails: e,
        type: "fail"
      });
    }).finally(() => {
      toggle();
    });
  }

  useEffect(() => {
    if (!loaded) {
      getRegistrants(props.event._id);
      setLoaded(true);
    }
  }, [loaded, getRegistrants, props.event._id]);

  return (
    <div className={styles.RegistrantsTab}>
      <div className={styles.actions}>
        <ContextMenu
          label={"Actions"}
          options={menuActions}
        />
      </div>
      <div className={styles.tableWrap}>
      <AgGridReact
        rowData={rowData}
        suppressRowTransform={true}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        theme={theme}
        pagination={true}
        paginationPageSize={20}
      />
      </div>
      <Modal
        isShown={isShown}
        hide={() => { toggle(); }}
        modalContent={<SendMessage handleSendMessage={emailRegistrants} />}
      />
    </div>
  );
};

export default RegistrantsTab;
