import React, { useState, useEffect, useCallback } from "react";
import { Constants } from "@eagerdog/constants";
import { IEvent, IShow, BreedGroupJudgeDto, IQuery, IBreed, BreedJudgeDto } from "@eagerdog/interfaces";

import { apiService } from "src/services";

import { toast } from "src/components/Toast/ToastManager";
import Input from "src/components/Input/Input";
import Checkbox, { ICheck } from "src/components/Checkbox/Checkbox";
import SearchDown from "src/components/SearchDown/SearchDown";
import Button from "src/components/Button/Button";
import Accordion, { Bellow, useAccordion } from "src/components/Accordion/Accordion";

import styles from "./JudgesTab.module.scss";

interface IProps {
  event: IEvent,
  show?: IShow,
  showElements: ICheck[],
  showType: string,
  onChange(judges: IJudge[], useSameJudge: boolean, breedGroups: BreedGroupJudgeDto[], breedSpecific: BreedJudgeDto[]): void
}

export interface IJudge {
  judge_element: string,
  judge_name: string,
  judge_number: string,
  breed_group?: string
}

enum breedErrors {
  NO_DATA
}

export const judgeArrangements = {
  element: "Element",
  breed_group: "Breed Group"
};

const JudgesTab: React.FC<IProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [showElements, setShowElements] = useState<ICheck[]>(props.showElements);

  const [breedGroups, setBreedGroups] = useState<BreedGroupJudgeDto[]>([]);

  const bellows:string[] = ["Element Judges", "Breed Group Judges", "Breed Specific Judges"];
  const { switchToBellow, activeBellow } = useAccordion();

  const [breedList, setBreedList] = useState<string[]>([]);
  const [breedValue, setBreedValue] = useState<string>("");
  const [breedExists, setBreedExists] = useState<boolean>(false);

  const [bsJudgeName, setBsJudgeName] = useState<string>("");
  const [bsJudgeNumber, setBsJudgeNumber] = useState<string>("");
  const [bsList, setBsList] = useState<BreedJudgeDto[]>(props.show?.breed_judges ? props.show?.breed_judges : []);

  const isUsingSameJudge = (elements: any) => {
    let judge:any = { judge_name: "", judge_number: "" };

    for (let i in elements) {
      if (judge.judge_name === "" && judge.judge_number === "") {
        judge = { judge_name: elements[i].judge_name, judge_number: elements[i].judge_number };
      } else {
        if (elements[i].judge_name !== judge.judge_name || elements[i].judge_number !== judge.judge_number) {
          return false;
        }
      }
    }

    return true;
  }

  const [useSameJudge, setUseSameJudge] = useState<boolean>(props.show ? isUsingSameJudge(props.show.show_elements) : true);
  const [judges, setJudges] = useState<IJudge[]>([]);

  const updateJudge = (judgeInfo: any, index: number) => {
    let _judges:IJudge[] = [...judges];

    _judges[index] = { ..._judges[index], ...judgeInfo };

    setJudges(_judges);
  }

  const updateBreedGroupJudge = (judgeInfo: any, index: number) => {
    let _breeds:BreedGroupJudgeDto[] = [...breedGroups];

    _breeds[index] = { ..._breeds[index], ...judgeInfo };

    setBreedGroups(_breeds);
  }

  const searchBreeds = (value:string) => {
    if (value.length > 0) {
      let query:IQuery = {
        $limit: 10,
        query: {
          $and: [
            {
              attribute_name: "breed",
              attribute_value: {
                operator: "$regex",
                value: value
              }
            }
          ]
        }
      };

      apiService.getBreeds(query).then((response) => {
        let breeds:string[] = []; 

        response.forEach((b: IBreed) => {
          breeds.push(b.breed);
        });

        setBreedList(breeds);
      });
    } else {
      setBreedList([]);
    }
  }

  const addBreedSpecificJudge = (breed: string, name: string, number: string) => {
    if (breedExists) {
      let _bsList:BreedJudgeDto[] = [...bsList];

      _bsList.push({
        breed: breed,
        judge_name: name,
        judge_number: number
      });

      setBsList(_bsList);

      setBsJudgeName("");
      setBsJudgeNumber("");
      setBreedValue("");
      setBreedExists(false);
    } else {
      toast.show({
        title: "Breed Specific Judge",
        content: "Please select a breed from the list",
        duration: 10000,
        type: "fail"
      });
    }
  }

  const deleteBreedSpecificJudge = (index: number) => {
    let _bsList:BreedJudgeDto[] = [...bsList];

    _bsList.splice(index, 1);

    setBsList(_bsList);
  }

  const usesBreedGroups = () => {
    if (props.event.sanctioning_club === Constants.sanctioning_club.UKC) {
      if (props.showType === Constants.show_type.conformation) {
        return true;
      }
    }

    return false;
  }

  useEffect(() => {
    setShowElements(props.showElements);

    const getJudges = () => {
      return props.showElements.map((se: any) => {
        let hasPreviousShowElement = props.show?.show_elements.filter((pse: any) => { return se.label === pse.show_element || se.label === pse.level; });

        if (hasPreviousShowElement && hasPreviousShowElement.length > 0) {
          let _pse:any = hasPreviousShowElement[0];

          return {
            judge_element: _pse.level ? _pse.level : _pse.show_element,
            judge_name: _pse.judge_name || "",
            judge_number: _pse.judge_number || ""
          };
        } else {
          return {
            judge_element: se.label,
            judge_name: se.judge_name || "",
            judge_number: se.judge_number || ""
          };
        }
      });
    }

    if (useSameJudge) {
      let _judges:IJudge[] = [];

      if (props.show) {
        _judges = [{
          judge_element: "",
          judge_name: props.show.show_elements[0].judge_name || "",
          judge_number: props.show.show_elements[0].judge_number || ""
        }];
      } else {
        _judges = [{
          judge_element: "",
          judge_name: "",
          judge_number: ""
        }];
      }

      setJudges(_judges);
    } else {
      let _judges:IJudge[] = [];

      if (props.show) {
        _judges = getJudges();
      } else {
        _judges = props.showElements.map((se: any) => {
          return {
            judge_element: se.label,
            judge_name: se.judge_name || "",
            judge_number: se.judge_number || ""
          };
        });
      }

      setJudges(_judges);
    }
  }, [props.showElements, useSameJudge, props.show]);

  let { onChange } = props;

  useEffect(() => {
    onChange(judges, useSameJudge, breedGroups, bsList);
  }, [judges, useSameJudge, onChange, breedGroups, bsList]);

  const getBreedGroups = useCallback(() => {
    return new Promise((resolve) => {
      let _query:any = {
        sort: [{
          attribute_name: "value",
          sort: "asc"
        }]
      };

      apiService.getBreedGroups(props.event.sanctioning_club, _query).then((response) => {
        resolve(response);
      }).catch((e) => {
        resolve(breedErrors.NO_DATA);
      });
    });
  }, [props.event.sanctioning_club]);

  const renderElementJudges = () => {
    return <>
      <p>Element Judges are required. If you don't provide any breed group or breed specific judges, the element judge will be shown.</p>
      <div className="judgeCheckWrap">
        <Checkbox id="sameJudge" label="Use the same judge for all shows" value={useSameJudge} onChange={(e:any) => { setUseSameJudge(!useSameJudge); }} />
      </div>
      {judges.map((j: IJudge, index: number) => {
        let hasShowElementChecked = showElements.filter((se: ICheck) => { return se.label === j.judge_element && se.checked === true; });

        if (j.judge_element === "" || hasShowElementChecked.length > 0) {
          return(
            <div key={"judge" + index} className="judgeInfo">
              <Input key={"name" + index} label={j.judge_element + " Judge Name"} id={j.judge_element + "judgeName"} type="text" onChange={(e) => { updateJudge({ judge_name: e.target.value }, index); }} placeholder={"What's the "+ (j.judge_element?.toLowerCase() || "") +" judges name?"} defaultValue={j.judge_name} />
              <Input key={"num" + index} label={"Judge #"} id={j.judge_element + "judgeNum"} type="text" onChange={(e) => { updateJudge({ judge_number: e.target.value }, index); }} defaultValue={j.judge_number} />
            </div>
          );
        } else {
          return(
            <div key={index}></div>
          );
        }
      })}
    </>;
  }

  useEffect(() => {
    if (!loaded) {
      const loadJudgesData = async() => {
        let breeds:any = await getBreedGroups();

        if (breeds !== breedErrors.NO_DATA) {
          let _breeds:BreedGroupJudgeDto[] = [];

          for (let b in breeds) {
            _breeds.push({
              breed_group: breeds[b].value,
              judge_name: "",
              judge_number: ""
            });
          }

          if (props.show) {
            if (props.show?.breed_group_judges) {
              for (let bgj in props.show.breed_group_judges) {
                for (let b in _breeds) {
                  if (_breeds[b].breed_group === props.show.breed_group_judges[bgj].breed_group) {
                    _breeds[b].judge_name = props.show.breed_group_judges[bgj].judge_name;
                    _breeds[b].judge_number = props.show.breed_group_judges[bgj].judge_number;

                    break;
                  }
                }
              }
            }
          }

          setBreedGroups(_breeds);
        }
      }

      loadJudgesData();

      setLoaded(true);
    }
  }, [loaded, props.event.sanctioning_club, getBreedGroups, props.show]);

  return (
    <div className={styles.JudgesTab}>
      <div className="stepTitle">Judges</div>
      <p>Who are the judges for your show?</p>
      {usesBreedGroups() && <>
        <Accordion bellows={bellows} activeBellow={activeBellow} onBellowClick={(index: number) => { switchToBellow(index); }}>
          <Bellow>
            {renderElementJudges()}
          </Bellow>
          <Bellow>
            <>
              <p>Breed Group Judges are optional. Breed Group Judges will replace Element Judges where applicable on generated forms. If you don't have a judge for a specific breed group, you can leave it empty.</p>
              {breedGroups.map((b: BreedGroupJudgeDto, index: number) => {
                return(
                  <div key={"judge" + index} className="judgeInfo">
                    <Input key={"name" + index} label={b.breed_group + " Judge Name"} id={b.breed_group + "judgeName"} type="text" onChange={(e) => { updateBreedGroupJudge({ judge_name: e.target.value }, index); }} placeholder={"What's the "+ (b.breed_group?.toLowerCase() || "") +" judges name?"} defaultValue={b.judge_name} />
                    <Input key={"num" + index} label={"Judge #"} id={b.breed_group + "judgeNum"} type="text" onChange={(e) => { updateBreedGroupJudge({ judge_number: e.target.value }, index); }} defaultValue={b.judge_number} />
                  </div>
                );
              })}
            </>
          </Bellow>
          <Bellow>
            <>
              <p>Breed Specific Judges are optional. Breed Specific Judges will replace both Breed Group and Element Judges where applicable on generated forms.</p>
              {<SearchDown
                label="Breed"
                id="breed"
                type="text"
                onChange={(e) => {
                  e.stopPropagation();
                  setBreedValue(e.target.value);
                  setBreedExists(false);
                }}
                onSelect={(value: string) => {
                  setBreedValue(value);
                  setBreedList([]);
                  setBreedExists(true);
                }}
                placeholder="What's the dog breed?"
                onSearch={searchBreeds}
                searchResults={breedList}
                defaultValue={breedValue}
              />}
              <div className={"judgeInfo breedSpecific"}>
                <Input label={"Judge Name"} id={"bsJudgeName"} type="text" onChange={(e) => { setBsJudgeName(e.target.value); }} placeholder="What's the judges name?" defaultValue={bsJudgeName} />
                <Input label={"Judge #"} id={"bsJudgeNum"} type="text" onChange={(e) => { setBsJudgeNumber(e.target.value); }} defaultValue={bsJudgeNumber} />
              </div>
              <div className={styles.bsActions}>
                <Button onClick={() => { addBreedSpecificJudge(breedValue, bsJudgeName, bsJudgeNumber); }} type="button">Add Judge</Button>
              </div>
              {bsList.length > 0 && <div className={styles.bsList}>
                <div className={`${styles.bsListItem} ${styles.bsListHeaders}`}>
                  <span>Breed</span>
                  <span>Judge Name</span>
                  <span>Judge Number</span>
                  <span></span>
                </div>
                {bsList.map((b: BreedJudgeDto, index: number) => {
                  return <div key={index} className={styles.bsListItem}>
                    <span>{b.breed}</span>
                    <span>{b.judge_name}</span>
                    <span>{b.judge_number}</span>
                    <div className={styles.bsListActions}>
                      <span onClick={() => { deleteBreedSpecificJudge(index); }} className={`${styles.icon} ${styles.delete}`}></span>
                    </div>
                  </div>;
                })}
              </div>}
            </>
          </Bellow>
        </Accordion>
      </>}
      {!usesBreedGroups() && renderElementJudges()}
    </div>
  );
};

export default JudgesTab;