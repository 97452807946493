import React, { useState, useEffect, useCallback } from "react";
import { IUser } from "@eagerdog/interfaces";
import moment from "moment";

import { apiService } from "src/services/api.service";

import type { ColDef } from "ag-grid-community";
import { themeQuartz } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

import { ActionsRenderer } from "./ActionsRenderer";
import { StatusRenderer } from "./StatusRenderer";

import styles from "./UserWaitlistTab.module.scss";

interface IProps {
  user: IUser
}


interface IRow {
  callName: string,
  status: string,
  eventName: string,
  showName: string,
  showElement: string,
  showLevel: string
  registeredAt: string,
  actions: any
}

const UserWaitlistTab: React.FC<IProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  const theme = themeQuartz.withParams({
    fontFamily: "Roboto",
    headerFontFamily: "Roboto",
    cellFontFamily: "Roboto"
  });

  const getWaitlists = useCallback((id: string) => {
    let query:any = {
      sort: [{
        attribute_name: "created_at",
        sort: "asc"
      }]
    };

    apiService.getUserWaitlists(id, query).then((response) => {
      let rows:IRow[] = [];

      for (let i in response) {
        let row:any = response[i];

        rows.push({
          callName: row.call_name,
          status: row.status,
          eventName: row.event.title,
          showName: row.show_type + " " + row.show_name,
          showElement: row.dog_class.show_element,
          showLevel: row.dog_class.level ? row.dog_class.level : "",
          registeredAt: moment(row.created_at).format("MMM Do YYYY, h:mmA zz"),
          actions: {
            waitlist: row
          }
        });
      }

      setRowData(rows);
    });
  }, []);

  let colDefs:ColDef<IRow>[] = [
    { field: "callName", minWidth: 120, cellDataType: "text", filter: true },
    {
      field: "status",
      minWidth: 150,
      filter: true,
      cellRenderer: StatusRenderer,
    },
    { field: "eventName", minWidth: 120, cellDataType: "text", filter: true },
    { field: "showName", minWidth: 120, cellDataType: "text", filter: true },
    { field: "showElement", minWidth: 120, cellDataType: "text", filter: true },
    { field: "showLevel", minWidth: 120, cellDataType: "text", filter: true },
    { field: "registeredAt", minWidth: 120, cellDataType: "text", filter: true },
    {
      headerName: "",
      field: "actions",
      maxWidth: 50,
      minWidth: 50,
      cellRenderer: ActionsRenderer,
      pinned: "right",
      cellRendererParams: {
        getWaitlists: () => { getWaitlists(props.user._id); }
      },
      resizable: false,
      cellStyle: {
        "overflow": "visible",
        "zIndex": "2"
      }
    }
  ];

  const [rowData, setRowData] = useState<IRow[]>([]);

  const defaultColDef: ColDef = {
    flex: 1
  };

  useEffect(() => {
    if (!loaded) {
      getWaitlists(props.user._id);
      setLoaded(true);
    }
  }, [loaded, getWaitlists, props.user._id]);

  return (
    <div className={styles.UserWaitlistTab}>
      <AgGridReact
        rowData={rowData}
        suppressRowTransform={true}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        theme={theme}
        pagination={true}
        paginationPageSize={20}
      />
    </div>
  );
};

export default UserWaitlistTab;