import React, { useEffect, useState } from "react";

import Input from "src/components/Input/Input";

import "./StopWatch.scss";

interface IProps {
  label?: string,
	onChange(time: string): void,
    time: string
}

export const convertTimeToMinutes = (time: number) => {
  let minutes:number = Math.floor(time/60);
  let seconds:number = time - (minutes * 60);
  let thousands:number = Math.round((seconds - Math.floor(seconds)) * 100);

  return (minutes.toString().length === 1 ? "0" + minutes : minutes) + ":" + (Math.floor(seconds).toString().length === 1 ? "0" + Math.floor(seconds) : Math.floor(seconds)) + ":" + (thousands.toString().length === 1 ? "0" + thousands : thousands);
}

export const convertTimeToSeconds = (time: string) => {
  let timeSplit:string[] = time.split(':');
  let timeInSeconds:number = 0;


  if (timeSplit.length === 3) {
    if (Number(timeSplit[0]) > 0) {
      let secondsToAdd:number = Number(timeSplit[0]) * 60;
      timeInSeconds += secondsToAdd
    }

    timeInSeconds += Number(timeSplit[1]);
  }

  return Number(timeInSeconds + "." + timeSplit[2]);
}

const StopWatch: React.FC<IProps> = (props) => {
    const [loaded, setLoaded] = useState<boolean>(false);

    const [seaMin, setSeaMin] = useState<string>("");
    const [seaSec, setSeaSec] = useState<string>("");
    const [seaTho, setSeaTho] = useState<string>("");

    useEffect(() => {
        if (!loaded) {
            setSeaMin(props.time.split(":")[0]);
            setSeaSec(props.time.split(":")[1]);
            setSeaTho(props.time.split(":")[2]);
            setLoaded(true);
        }
    }, [loaded, props.time]);

    const goToNext = (e: any) => {
        let t:string = "seaMin";

        let noNoKeys:string[] = [
          "Backspace",
          "Tab",
          "ShiftLeft",
          "ShiftRight",
          "ControlLeft",
          "ControlRight",
          "KeyA"
        ];

        switch(e.target.id) {
          case "seaMin":
            t = "seaSec";
          break;
          case "seaSec":
            t = "seaTho";
          break;
        }

        if (!noNoKeys.includes(e.code) && !isNaN(e.target.value) && e.target.value.length >= 2) {
          document.getElementById(t)?.focus();
        }
    }

    return (
    <div className="StopWatch reason qualified">
      <Input
        label={props.label ? props.label + " (mm)" : "Search Time (mm)"}
        id="seaMin"
        type="text"
        onChange={(e) => {
          e.stopPropagation();
          setSeaMin(e.target.value);
          props.onChange(e.target.value+":"+seaSec+":"+seaTho);
        }}
        placeholder={"mm"}
        defaultValue={seaMin}
        maxLength={2}
        onKeyUp={(e:any) => { goToNext(e); }}
      />
      <Input
        label={props.label ? props.label + " (ss)" : "Search Time (ss)"}
        id="seaSec"
        type="text"
        onChange={(e) => {
          e.stopPropagation();
          setSeaSec(e.target.value);
          props.onChange(seaMin+":"+e.target.value+":"+seaTho);
        }}
        placeholder={"ss"}
        defaultValue={seaSec}
        maxLength={2}
        onKeyUp={(e:any) => { goToNext(e); }}
      />
      <Input
        label={props.label ? props.label + " (tt)" : "Search Time (tt)"}
        id="seaTho"
        type="text"
        onChange={(e) => {
          e.stopPropagation();
          setSeaTho(e.target.value);
          props.onChange(seaMin+":"+seaSec+":"+e.target.value);
        }}
        placeholder={"tt"}
        defaultValue={seaTho}
        maxLength={2}
      />
    </div>
    );
};

export default StopWatch;