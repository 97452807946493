import { Constants } from "@eagerdog/constants";
import { IEventRegisterRow } from "../../../services/helper.service.js";

export namespace UKCShedDog {
  export const form: IEventRegisterRow[] = [
    {
      allowDuplicates: false,
      inputs: [
        {
          type: "checkbox",
          label: Constants.show_type.shed_dog,
          multiSelect: true,
          options: [
            {
              value: Constants.dog_class_element_level.working_shed_dog,
              id: Constants.dog_class_element_level.working_shed_dog,
            },
            {
              value: Constants.dog_class_element_level.shed_dog_champion,
              id: Constants.dog_class_element_level.shed_dog_champion,
            },
            {
              value: Constants.dog_class_element_level.shed_dog_youth,
              id: Constants.dog_class_element_level.shed_dog_youth,
            },
          ],
        },
      ],
    },
  ];
}
