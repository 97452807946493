import React, { useState, useEffect } from "react";
import moment from "moment";
import { Constants } from "@eagerdog/constants";
import { IEventSummary, IShow } from "@eagerdog/interfaces";

import { helperService } from "src/services/helper.service";
import { apiService } from "src/services";

import TabContent, { ITab, useTabContent } from "src/components/TabContent/TabContent";
import Dropdown, { IOption } from "src/components/Dropdown/Dropdown";

import NotificationsTab from "./NotificationsTab/NotificationsTab";
import JudgeSchedulesTab from "./JudgeSchedulesTab/JudgeSchedulesTab";

import styles from "./ManageShow.module.scss";

interface IProps {
  event: IEventSummary,
  show: IShow,
  onUpdate(): void
}


const ManageShow: React.FC<IProps> = (props) => {
  const { activeTab, setTab } = useTabContent();
  const [addedRing, setAddedRing] = useState<boolean>(false);

  const [ringName, setRingName] = useState<IOption>(props.show.ring ? { id: props.show.ring, value: props.show.ring } : { id: Constants.ring_names.ring_1, value: Constants.ring_names.ring_1 });
  const ringOptions:IOption[] = [
    { id: Constants.ring_names.ring_1, value: Constants.ring_names.ring_1 },
    { id: Constants.ring_names.ring_2, value: Constants.ring_names.ring_2 },
    { id: Constants.ring_names.ring_3, value: Constants.ring_names.ring_3 },
    { id: Constants.ring_names.ring_4, value: Constants.ring_names.ring_4 }
  ];

  const supportsJudgeSchedules = () => {
    return (props.event.sanctioning_club === Constants.sanctioning_club.AKC && (props.show.show_type === Constants.show_type.obedience || props.show.show_type === Constants.show_type.rally_obedience));
  }

  const tabs:ITab[] = supportsJudgeSchedules() ? [
    { name: "Judge Schedules", actions: [] },
    { name: "Notifications", actions: [] }
  ] : [
    { name: "Notifications", actions: [] }
  ];

  let { show, event, onUpdate } = props;
  const pRingName:any = helperService.usePrevious(ringName);

  useEffect(() => {
    if (show.ring === undefined && addedRing === false) {
      // Update old shows that don't have a ring selected.
      let _show:any = {...show, ring: ringName.id};
      delete _show.__v;

      apiService.updateShow(event._id, show._id, _show).then((response) => {
        onUpdate();
        setAddedRing(true);
      });
    } else {
      if (pRingName && ringName.id !== pRingName.id) {
        let _show:any = {...show, ring: ringName.id};
        delete _show.__v;

        apiService.updateShow(event._id, show._id, _show).then((response) => {
          onUpdate();
        });
      }
    }
  }, [ringName, show, event, onUpdate, pRingName, addedRing]);

  return (
    <div className={styles.ManageShow}>
      <div className={styles.title}>
        <span className={styles.ModalTitle}>Manage Show</span>
        <span className={styles.ShowName}>{props.show.show_type} {props.show.show_name} ({moment(props.show.show_date).format("MMM Do")})</span>
        <div className={styles.RingSelection}>
          <Dropdown className={styles.RingName} value={ringName} label={"Ring Location"} onChange={(e, value: IOption) => { setRingName(value); }} options={ringOptions} placeholder="" />
        </div>
      </div>
      <div className={styles.manageShowContent}>
        {supportsJudgeSchedules() && <TabContent className={"ManageShowTabs"} setTab={setTab} activeTab={activeTab} tabs={tabs}>
          <JudgeSchedulesTab event={props.event} show={props.show} onUpdate={props.onUpdate} />
          <NotificationsTab event={props.event} show={props.show} onUpdate={props.onUpdate} />
        </TabContent>}
        {!supportsJudgeSchedules() && <TabContent className={"ManageShowTabs"} setTab={setTab} activeTab={activeTab} tabs={tabs}>
          <NotificationsTab event={props.event} show={props.show} onUpdate={props.onUpdate} />
        </TabContent>}
      </div>
    </div>
  );
};

export default ManageShow;