import { useRef, useEffect } from "react";
import { localStorageService } from "./local-storage.service";
import { IUser } from "@eagerdog/interfaces";
import { IOption } from "../components/Dropdown/Dropdown";
import { Constants } from "@eagerdog/constants";

import dog1 from "../assets/ren_450x450.png";
import dog2 from "../assets/raeah_450x450.png";
import dog3 from "../assets/stan_450x450.png";
import dog4 from "../assets/kai_450x450.png";
import dog5 from "../assets/rumor_450x450.png";
import dog6 from "../assets/siri_450x450.png";

export interface IEventRegisterInput {
  type: string;
  flex?: number;
  dropUp?: boolean;
  defaultValue?: string;
  multiSelect?: boolean;
  label: string;
  options: IOption[];
}

export interface IEventRegisterRow {
  allowDuplicates?: boolean;
  skip?: boolean;
  inputs: IEventRegisterInput[];
}

export interface IDogPortrait {
  image: string;
  name: string;
}

export interface IShowType {
  name: string;
  disabled: boolean;
}

export const dogHeightTypes: IOption[] = [
  { id: "None", value: "None" },
  {
    id: Constants.jump_height_type.minimum,
    value: Constants.jump_height_type.minimum,
  },
  {
    id: Constants.jump_height_type.standard,
    value: Constants.jump_height_type.standard,
  },
];

export const dogRallyHeights: IOption[] = [
  { id: "4", value: '4"' },
  { id: "8", value: '8"' },
  { id: "12", value: '12"' },
  { id: "16", value: '16"' },
];

export const dogObedienceHeights: IOption[] = [
  { id: "4", value: '4"' },
  { id: "6", value: '6"' },
  { id: "8", value: '8"' },
  { id: "10", value: '10"' },
  { id: "12", value: '12"' },
  { id: "14", value: '14"' },
  { id: "16", value: '16"' },
  { id: "18", value: '18"' },
  { id: "20", value: '20"' },
  { id: "22", value: '22"' },
  { id: "24", value: '24"' },
  { id: "26", value: '26"' },
  { id: "28", value: '28"' },
  { id: "30", value: '30"' },
];

export const nonLicensed: string[] = [
  Constants.dog_class_element.novice_puppy,
  Constants.dog_class_element.veteran,
  Constants.dog_class_element.brood_bitch_stud_dog,
  Constants.dog_class_element.brace,
  Constants.dog_class_element.total_dog,
  //Constants.dog_class_element.abi_best_of_breed, //DO temporary commented out
  Constants.dog_class_element.abi_bred_by_exibitor,
  Constants.dog_class_element.akc_futurity,
  Constants.dog_class_element.akc_sweepstakes,
  Constants.dog_class_element.junior_showmanship,
  Constants.dog_class_element_level.total_junior,
  Constants.dog_class_element_level.fun_run,
  Constants.dog_class_element_level.puppy_dash,
  Constants.dog_class_element_level.abi_baby_puppy
];

export const separatePricedElements: string[] = [
  Constants.dog_class_element.novice_puppy,
  Constants.dog_class_element.veteran,
  Constants.dog_class_element.brood_bitch_stud_dog,
  Constants.dog_class_element.brace,
  Constants.dog_class_element.total_dog,
  Constants.dog_class_element.abi_best_of_breed,
  Constants.dog_class_element.abi_bred_by_exibitor,
  Constants.dog_class_element.akc_futurity,
  Constants.dog_class_element.akc_sweepstakes,
  Constants.dog_class_element.junior_showmanship,
  Constants.dog_class_element_level.total_junior,
  Constants.dog_class_element.scentwork_container,
  Constants.dog_class_element.scentwork_interior,
  Constants.dog_class_element.scentwork_exterior,
  Constants.dog_class_element.scentwork_buried,
  Constants.dog_class_element.scentwork_handler_disc,
  Constants.dog_class_element.scentwork_detective,
];

export namespace helperService {
  export const showTypes: IShowType[] = [
    { name: "Conformation", disabled: true },
    { name: "Nosework", disabled: false },
    { name: "Dock Jumping", disabled: true },
    { name: "Rally Obedience", disabled: true },
    { name: "Weight Pull", disabled: true },
    { name: "Obedience", disabled: true },
    { name: "Agility", disabled: true },
    { name: "Lure Coursing", disabled: true },
  ];

  export const allDogs: IDogPortrait[] = [
    { image: dog1, name: "Ren" },
    { image: dog2, name: "Raeah" },
    { image: dog3, name: "Stan" },
    { image: dog4, name: "Kai" },
    { image: dog5, name: "Rumor" },
    { image: dog6, name: "Siri" },
  ];

  export const topDogs: IDogPortrait[] = [allDogs[0], allDogs[2]];

  export const bottomDogs: IDogPortrait[] = [
    allDogs[3],
    allDogs[4],
    allDogs[5],
  ];

  export const timeSlotShows: string[] = [
    Constants.show_type.fast_cat,
    Constants.show_type.fetch,
  ];

  export const getEventStatus = (status: string) => {
    let statusText: string = "";

    switch (status.toLowerCase()) {
      case Constants.event_status.published:
        statusText = "Live";
        break;
      case Constants.event_status.draft:
        statusText = "Upcoming";
        break;
      case Constants.event_status.complete:
        statusText = "Closed";
        break;
      default:
        statusText = "Upcoming";
        break;
    }

    return statusText;
  };

  export const usePrevious = (value: any) => {
    const ref = useRef();

    useEffect(() => {
      ref.current = value;
    });

    return ref.current;
  };

  export const formatTime = (d: Date) => {
    let hours: number = d.getHours();
    let minutes: string | number = d.getMinutes();
    let ampm: string | number = hours >= 12 ? "pm" : "am";

    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    let strTime: string = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  export const isLoggedIn = () => {
    if (
      localStorageService.getItem("user") &&
      localStorageService.getItem("access_token")
    ) {
      return true;
    }

    return false;
  };

  export const isValidEmail = (email: string) => {
    var regex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/gm;

    return new RegExp(regex).test(email);
  };

  export const getUser = () => {
    const userString = localStorageService.getItem("user");

    let user: IUser = {
      _id: "",
      user_type: "",
      email: "",
      display_name: "",
      auth_type: "",
      website: "",
      is_email_verified: false,
    };

    if (userString) {
      user = JSON.parse(userString);
    }

    return user;
  };

  export const logout = (callback?: () => void) => {
    localStorageService.removeItem("user");
    localStorageService.removeItem("access_token");

    if (callback) {
      callback();
    }
  };
}
