import { Constants } from "@eagerdog/constants";
import { IEventRegisterRow } from "../../../services/helper.service.js";

export namespace AKCConformation {
  export const form: IEventRegisterRow[] = [
    {
      allowDuplicates: false,
      inputs: [
        {
          type: "checkbox",
          label: Constants.dog_class_element.conformation,
          multiSelect: false,
          options: [
            {
              value: Constants.dog_class_element_level.akc_4_6_month_puppy,
              id: Constants.dog_class_element_level.akc_4_6_month_puppy,
            },
            {
              value: Constants.dog_class_element_level.akc_6_9_month_puppy,
              id: Constants.dog_class_element_level.akc_6_9_month_puppy,
            },
            {
              value: Constants.dog_class_element_level.akc_9_12_month_puppy,
              id: Constants.dog_class_element_level.akc_9_12_month_puppy,
            },
            {
              value: Constants.dog_class_element_level.akc_12_18_month_puppy,
              id: Constants.dog_class_element_level.akc_12_18_month_puppy,
            },
            {
              value:
                Constants.dog_class_element_level
                  .akc_under_12_month_bred_by_exhibitor,
              id: Constants.dog_class_element_level
                .akc_under_12_month_bred_by_exhibitor,
            },
            {
              value:
                Constants.dog_class_element_level.akc_adult_bred_by_exhibitor,
              id: Constants.dog_class_element_level.akc_adult_bred_by_exhibitor,
            },
            {
              value:
                Constants.dog_class_element_level.akc_amateur_owner_handler,
              id: Constants.dog_class_element_level.akc_amateur_owner_handler,
            },
            {
              value: Constants.dog_class_element_level.akc_american_bred,
              id: Constants.dog_class_element_level.akc_american_bred,
            },
            {
              value: Constants.dog_class_element_level.akc_open,
              id: Constants.dog_class_element_level.akc_open,
            },
            {
              value: Constants.dog_class_element_level.akc_best_of_breed,
              id: Constants.dog_class_element_level.akc_best_of_breed,
            },
            {
              value: Constants.dog_class_element_level.akc_veteran_8_10_years,
              id: Constants.dog_class_element_level.akc_veteran_8_10_years,
            },
            {
              value: Constants.dog_class_element_level.akc_veteran_10_12_years,
              id: Constants.dog_class_element_level.akc_veteran_10_12_years,
            },
            {
              value:
                Constants.dog_class_element_level.akc_veteran_13_plus_years,
              id: Constants.dog_class_element_level.akc_veteran_13_plus_years,
            },
          ],
        },
      ],
    },
    {
      allowDuplicates: false,
      inputs: [
        {
          type: "checkbox",
          label: Constants.dog_class_element.conformation_non_licensed,
          multiSelect: true,
          options: [
            {
              value: Constants.dog_class_element_level.akc_sweepstakes,
              id: Constants.dog_class_element_level.akc_sweepstakes,
            },
            {
              value: Constants.dog_class_element_level.akc_futurity,
              id: Constants.dog_class_element_level.akc_futurity,
            },
          ],
        },
      ],
    },
  ];
}
