import React from 'react';

import { Modal, useModal } from "src/components/Modal/Modal";
import Refund from "src/components/Refund/Refund";
import ContextMenu from "src/components/ContextMenu/ContextMenu";

import type { CustomCellRendererProps } from 'ag-grid-react';

import styles from "./TransactionsTab.module.scss";

interface CustomTransactionCellRendererProps extends CustomCellRendererProps {
  getTransactions(): void
}

export const ActionsRenderer = (props: CustomTransactionCellRendererProps) => {
  const { isShown, toggle } = useModal();

  return (<div className={styles.ActionRenderer}>
    <ContextMenu
      options={[
        { 
          label: "Refund Transaction",
          onClick:() => {
            toggle();
          }
        }
      ]}
    />
    <Modal
      className="refundModal"
      isShown={isShown}
      hide={() => { toggle(); }}
      modalContent={<Refund
          transaction={props.value.transaction}
          onRefund={() => {
            toggle();
            props.getTransactions();
          }}
          onCancel={() => {
            toggle();
          }}
      />}
    />
  </div>);
};
