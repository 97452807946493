import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AxiosError } from "axios";
import { IEventSummary, IUser } from "@eagerdog/interfaces";

import { apiService } from "src/services/api.service";
import { helperService} from "../../services/helper.service";

import TabContent, { ITab, useTabContent } from "src/components/TabContent/TabContent";
import { toast } from "src/components/Toast/ToastManager";

import RegistrantsTab from "./RegistrantsTab/RegistrantsTab";
import DiscountsTab from "./DiscountsTab/DiscountsTab";
import ResultsTab from "./ResultsTab/ResultsTab";
import TransactionsTab from "./TransactionsTab/TransactionsTab";
import WaitlistTab from "./WaitlistTab/WaitlistTab";

import styles from "./EventManage.module.scss";

enum eventErrors {
  NOT_AUTHORIZED,
  NOT_FOUND,
  NO_METRICS
}

interface IEventMetrics {
  dogs: number,
  entries: number,
  revenue: number
}

const EventManage: React.FC = () => {
  const [loaded, setLoaded] = useState<boolean>(false);

  const [event, setEvent] = useState<IEventSummary>();
  const [metrics, setMetrics] = useState<IEventMetrics>();

  const navigate = useNavigate();
  const user:IUser = helperService.getUser();
  let { eid } = useParams();
  const { activeTab, setTab } = useTabContent();

  const [currencyFormatter, setCurrentFormatter] = useState<any>(new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }));

  const tabs:ITab[] = [
    { name: "Registrants", actions: [] },
    { name: "Discounts", actions: [] },
    { name: "Results", actions: [] },
    { name: "Transactions", actions: [] },
    { name: "Waitlist", actions: [] }
  ];

  const isClubOwner = (createdBy: string, userId: string) => {
    if (createdBy !== userId) {
      return false;
    }

    return true;
  }

  const getEvent = useCallback((eid: string) => {
    return new Promise((resolve) => {
      apiService.getEventSummaryForHandle(eid).then((response) => {
        if (response?.club?.created_by) {
          if (!isClubOwner(response.club.created_by, user._id)) {
            resolve(eventErrors.NOT_AUTHORIZED);
          } else {
            resolve(response);
          }
        }
      }).catch((e: AxiosError) => {
        resolve(eventErrors.NOT_FOUND);
      });
    });
  }, [user._id]);

  const getEventMetrics = (eventId: string) => {
    return new Promise((resolve) => {
      apiService.getEventMetrics(eventId).then((response) => {
        resolve(response);
      }).catch((e: AxiosError) => {
        resolve(eventErrors.NO_METRICS);
      });
    });
  }

  useEffect(() => {
    if (!loaded && eid) {
      const queryParameters = new URLSearchParams(window.location.search);
      const paramShow = queryParameters.get("show");

      if (paramShow) {
        setTab(2);
      }

      const showError = (msg: string, url?: string) => {
        toast.show({
          title: "Event Manage",
          content: msg,
          duration: 10000,
          type: "fail"
        });

        if (url) {
          navigate(url);
        }
      }

      const loadEventData = async () => {
        if (eid) {
          const event:any = await getEvent(eid);

          if (event._id) {
            setEvent(event);

            setCurrentFormatter(new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: event.currency || "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }));

            const metrics:any = await getEventMetrics(event._id);

            if (metrics.dogs !== undefined && metrics.entries !== undefined && metrics.revenue !== undefined) {
              setMetrics(metrics);
            } else {
              showError("Error getting metrics");
            }
          } else {
            if (event === eventErrors.NOT_FOUND) {
              showError("Event not found", "/");
            } else {
              showError("Only club owners can manage an event", "/e/" + eid);
            }
          }

          setLoaded(true);
        }
      }

      loadEventData();
    }
  }, [loaded, eid, getEvent, navigate, setTab]);

  return (
    <div className={styles.EventManage}>
      {event && <Helmet>
        <title>{event?.title} - Manage Event</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>}
      <div className={styles.inner}>
        <div className={styles.breadcrumb}>
          <div className={styles.path}>
            <Link to={"/e/" + eid}>{event ? event.title : "Event"}</Link> / </div><div className={styles.current}>Manage Event
          </div>
        </div>
        <h1>Manage Event</h1>
        {metrics && <div className={styles.metrics}>
          <div className={styles.metricItem}>
            <div className={styles.num}>{metrics.dogs}</div>
            <div className={styles.label}>Dogs Registered</div>
          </div>
          <div className={styles.metricItem}>
            <div className={styles.num}>{metrics.entries}</div>
            <div className={styles.label}>Entries</div>
          </div>
          <div className={styles.metricItem}>
            <div className={styles.num}>{currencyFormatter.format(metrics.revenue)}</div>
            <div className={styles.label}>Total Revenue</div>
          </div>
        </div>}
        {event && <TabContent setTab={setTab} activeTab={activeTab} tabs={tabs}>
          <><RegistrantsTab event={event} /></>
          <DiscountsTab event={event} />
          <ResultsTab event={event} />
          <TransactionsTab event={event} />
          <WaitlistTab event={event} />
        </TabContent>}
      </div>
    </div>
  );
};

export default EventManage;