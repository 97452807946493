import { Constants } from "./constants";

export const ElementRunningTimes: any = {
  [Constants.sanctioning_club.AKC]: {
    [Constants.show_type.rally_obedience]: {
      [Constants.dog_class_element_level.novice_a]:
        Constants.running_order_times.AKC.rally_obedience.novice_a,
      [Constants.dog_class_element_level.novice_b]:
        Constants.running_order_times.AKC.rally_obedience.novice_b,
      [Constants.dog_class_element_level.intermediate]:
        Constants.running_order_times.AKC.rally_obedience.intermediate,
      [Constants.dog_class_element_level.advanced_a]:
        Constants.running_order_times.AKC.rally_obedience.advanced_a,
      [Constants.dog_class_element_level.advanced_b]:
        Constants.running_order_times.AKC.rally_obedience.advanced_b,
      [Constants.dog_class_element_level.excellent_a]:
        Constants.running_order_times.AKC.rally_obedience.excellent_a,
      [Constants.dog_class_element_level.excellent_b]:
        Constants.running_order_times.AKC.rally_obedience.excellent_b,
      [Constants.dog_class_element_level.master]:
        Constants.running_order_times.AKC.rally_obedience.master,
      [Constants.dog_class_element_level.choice]:
        Constants.running_order_times.AKC.rally_obedience.choice,
      [Constants.dog_class_element_level.pairs]:
        Constants.running_order_times.AKC.rally_obedience.pairs,
      [Constants.dog_class_element_level.team]:
        Constants.running_order_times.AKC.rally_obedience.team,
    },
    [Constants.show_type.obedience]: {
      [Constants.dog_class_element_level.pre_novice]:
        Constants.running_order_times.AKC.obedience.pre_novice,
      [Constants.dog_class_element_level.beginner_novice]:
        Constants.running_order_times.AKC.obedience.beginner_novice,
      [Constants.dog_class_element_level.beginner_novice_a]:
        Constants.running_order_times.AKC.obedience.beginner_novice_a,
      [Constants.dog_class_element_level.beginner_novice_b]:
        Constants.running_order_times.AKC.obedience.beginner_novice_b,
      [Constants.dog_class_element_level.preferred_novice]:
        Constants.running_order_times.AKC.obedience.preferred_novice,
      [Constants.dog_class_element_level.novice_a]:
        Constants.running_order_times.AKC.obedience.novice_a,
      [Constants.dog_class_element_level.novice_b]:
        Constants.running_order_times.AKC.obedience.novice_b,
      [Constants.dog_class_element_level.novice_c]:
        Constants.running_order_times.AKC.obedience.novice_c,
      [Constants.dog_class_element_level.advance_novice]:
        Constants.running_order_times.AKC.obedience.advance_novice,
      [Constants.dog_class_element_level.graduate_novice]:
        Constants.running_order_times.AKC.obedience.graduate_novice,
      [Constants.dog_class_element_level.open_a]:
        Constants.running_order_times.AKC.obedience.open_a,
      [Constants.dog_class_element_level.open_b]:
        Constants.running_order_times.AKC.obedience.open_b,
      [Constants.dog_class_element_level.advance_open]:
        Constants.running_order_times.AKC.obedience.advance_open,
      [Constants.dog_class_element_level.preferred_open]:
        Constants.running_order_times.AKC.obedience.preferred_open,
      [Constants.dog_class_element_level.graduate_open]:
        Constants.running_order_times.AKC.obedience.graduate_open,
      [Constants.dog_class_element_level.utility_a]:
        Constants.running_order_times.AKC.obedience.utility_a,
      [Constants.dog_class_element_level.utility_b]:
        Constants.running_order_times.AKC.obedience.utility_b,
      [Constants.dog_class_element_level.preferred_utility]:
        Constants.running_order_times.AKC.obedience.preferred_utility,
      [Constants.dog_class_element_level.wc_novice]:
        Constants.running_order_times.AKC.obedience.wc_novice,
      [Constants.dog_class_element_level.wc_open]:
        Constants.running_order_times.AKC.obedience.wc_open,
      [Constants.dog_class_element_level.wc_utility]:
        Constants.running_order_times.AKC.obedience.wc_utility,
      [Constants.dog_class_element_level.udx]:
        Constants.running_order_times.AKC.obedience.udx,
    },
  },
};
