import React, { useState } from 'react';

import styles from './Accordion.module.scss';

interface IBellow {
  children: string | JSX.Element | JSX.Element[],
}

export const useAccordion = () => {
  const [activeBellow, setActiveBellow] = useState<number>(0);

  const switchToBellow = (index:number) => {
    setActiveBellow(index);
  }

  return { switchToBellow, activeBellow };
}

export const Bellow: React.FC<IBellow> = (props) => {
  return (
    <div className={styles.bellow}>
      {props.children}
    </div>
  );
}

interface IProps {
  bellows: string[],
  children: React.ReactElement<IBellow>[],
  activeBellow: number,
  onBellowClick(index:number): void
}

const Accordion: React.FC<IProps> = (props) => {
  let offset:number = 0;

  return (
    <div className={styles.Accordion}>
      <div className={styles.bellows}>
        <div className={styles.bellowsInner}>
          {props.children.map((c:React.ReactElement<IBellow>, childIndex:number) => {
            if (!c.props.children) {
              offset++;
              return(false);
            } else {
              return(
                <div key={childIndex - offset} className={props.activeBellow === childIndex ? `${styles.bellowWrap} ${styles.active}` : `${styles.bellowWrap}`}>
                  <div onClick={() => { props.onBellowClick(childIndex); }} className={styles.bellowTitle}>
                    <span className={styles.bellowTitleWrap}>{props.bellows[childIndex]}</span>
                    <div className={styles.bellowExpand}>+</div>
                  </div>
                  {c}
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}

export default Accordion;