import React, { useState, useEffect, useCallback } from "react";
import { IEventSummary } from "@eagerdog/interfaces";

import { apiService } from "src/services/api.service";

import { Modal, useModal } from "src/components/Modal/Modal";
import ContextMenu, { IContextMenuOption } from "src/components/ContextMenu/ContextMenu";
import DiscountForm from "src/components/DiscountForm/DiscountForm";

import type { ColDef } from "ag-grid-community";
import { themeQuartz } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

import { ActionsRenderer } from "./ActionsRenderer";

import styles from "./DiscountsTab.module.scss";

interface IProps {
  event: IEventSummary
}

interface IRow {
  discountCode: string,
  discountValue: string,
  discountType: string,
  actions: any
}

const DiscountsTab: React.FC<IProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  const { isShown, toggle } = useModal();

  const menuActions:IContextMenuOption[] = [
    {
      label: "Create Discount",
      onClick: () => {
        toggle();
      }
    }
  ];

  const theme = themeQuartz.withParams({
    fontFamily: "Roboto",
    headerFontFamily: "Roboto",
    cellFontFamily: "Roboto"
  });

  const getDiscounts = useCallback((id: string) => {
    let query:any = {
      sort: [{
        attribute_name: "show_type",
        sort: "asc"
      }, {
        attribute_name: "created_at",
        sort: "asc"
      }],
    };

    apiService.getEventDiscounts(id, query).then((response) => {
      let rows:IRow[] = [];

      for (let i in response) {
        let row:any = response[i];

        rows.push({
          discountCode: row.discount_code,
          discountValue: row.discount_value.toString(),
          discountType: row.discount_type[0].toUpperCase() + response[i].discount_type.slice(1),
          actions: {
            discount: row,
            event: props.event
          }
        });
      }

      setRowData(rows);
    });
  }, [props.event]);

  let colDefs:ColDef<IRow>[] = [
    { field: "discountCode", minWidth: 120, cellDataType: "text", filter:true },
    { field: "discountValue", minWidth: 150, filter:true },
    { field: "discountType", minWidth: 150, filter:true },
    {
      headerName: "",
      field: "actions",
      maxWidth: 50,
      minWidth: 50,
      cellRenderer: ActionsRenderer,
      pinned: "right",
      cellRendererParams: {
        getDiscounts: () => { getDiscounts(props.event._id); }
      },
      resizable: false,
      cellStyle: {
        "overflow": "visible",
        "zIndex": "2"
      }
    }
  ];

  const [rowData, setRowData] = useState<IRow[]>([]);

  const defaultColDef: ColDef = {
    flex: 1
  };

  useEffect(() => {
    if (!loaded) {
      getDiscounts(props.event._id);
      setLoaded(true);
    }
  }, [loaded, getDiscounts, props.event._id]);

  return (
    <div className={styles.DiscountsTab}>
      <div className={styles.actions}>
        <ContextMenu
          label={"Actions"}
          options={menuActions}
        />
      </div>
      <div className={styles.tableWrap}>
        <AgGridReact
          rowData={rowData}
          suppressRowTransform={true}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          theme={theme}
          pagination={true}
          paginationPageSize={20}
        />
      </div>
      <Modal
        className="discountModal"
        isShown={isShown}
        hide={() => { toggle(); }}
        modalContent={<DiscountForm
          eventId={props.event._id}
          onFinish={() => {
              toggle();
              getDiscounts(props.event._id);
            }
          }
        />}
      />
    </div>
  );
};

export default DiscountsTab;