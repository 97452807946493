import React, { useEffect, useState } from "react";
import { Constants } from "@eagerdog/constants";
import { RallyObedienceScoreDto } from "@eagerdog/interfaces";

import Input from "src/components/Input/Input";
import Dropdown, { IOption } from "src/components/Dropdown/Dropdown";
import StopWatch, { convertTimeToSeconds, convertTimeToMinutes } from "../StopWatch/StopWatch";

import "./RallyObedienceResults.scss";

interface IProps {
  result: any,
  onChange(result: RallyObedienceScoreDto): void
}

const RallyObedienceResults: React.FC<IProps> = (props) => {
  const getOptionLabel = (id: string) => {
    switch (id) {
      case Constants.score_result_type.qualified:
        return "Qualified";
      case Constants.score_result_type.not_qualified:
        return "Not Qualified";
      case Constants.score_result_type.disqualified:
        return "Disqualified";
      case Constants.score_result_type.excused:
        return "Excused";
      case Constants.score_result_type.absent:
        return "Absent";
    }

    return "None";
  }

  const [deductions, setDeductions] = useState<string>(props.result.rally_obedience?.deductions ? props.result.rally_obedience?.deductions.toString() : "0");
  const [result, setResult] = useState<IOption>({ id: props.result.rally_obedience?.result_type ? props.result.rally_obedience?.result_type : "None", value: props.result.rally_obedience?.result_type ? getOptionLabel(props.result.rally_obedience?.result_type) : "None" });
  const [time, setTime] = useState<string>(props.result.rally_obedience?.time ? convertTimeToMinutes(props.result.rally_obedience?.time.toString()) : "");

  const resultOptions:IOption[] = [
    { id: "None", value: "None" },
    { id: Constants.score_result_type.qualified, value: "Qualified" },
    { id: Constants.score_result_type.not_qualified, value: "Not Qualified" },
    { id: Constants.score_result_type.disqualified, value: "Disqualified" },
    { id: Constants.score_result_type.excused, value: "Excused" },
    { id: Constants.score_result_type.absent, value: "Absent" }
  ]

  const [hasChanged, setHasChanged] = useState<boolean>(false);

  useEffect(() => {
    if (hasChanged) {
      props.onChange({
        result_type: result.id,
        deductions: Number(deductions),
        time: convertTimeToSeconds(time),
      });

      setHasChanged(false);
    }
  }, [hasChanged, props, deductions, result.id, time]);

  return (
    <div className="RallyObedienceResults">
      <div className="row"><Dropdown value={result} onChange={(e: any, value: IOption) => { setResult(value); setHasChanged(true); }} label="Result" options={resultOptions} placeholder="" /></div>
      {result.id === Constants.score_result_type.qualified && <>
        <div className="row"><Input label="Deductions (Total Point Deduction)" id="deductions" type="number" min="0" required onChange={(e) => { e.stopPropagation(); setDeductions(e.target.value); setHasChanged(true); }} placeholder="" defaultValue={deductions} /></div>
        <div className="row"><StopWatch time={time} onChange={(value: string) => { setTime(value); setHasChanged(true); }} /></div>
      </>}
    </div>
  );
};

export default RallyObedienceResults;