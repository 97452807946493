import React, { useRef, useState, useEffect } from "react";
import { createPortal } from "react-dom";

import { helperService } from "src/services/helper.service";

import styles from "./ContextMenu.module.scss";

export interface IContextMenuOption {
  label: string,
  onClick(e: any): void
}

interface IProps {
  label?: string,
  options: IContextMenuOption[]
}

const ContextMenu: React.FC<IProps> = (props) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const iconRef = useRef<HTMLDivElement>(null);
  const optionRef = useRef<HTMLUListElement>(null);

  const [right, setRight] = useState<number>(0);
  const [top, setTop] = useState<number>(0);

  const [heightOffset, setHeightOffset] = useState<number>(0);
  const [widthOffset, setWidthOffset] = useState<number>(0);

  const [menuOffset, setMenuOffset] = useState<number>(0);

  const pShowMenu = helperService.usePrevious(showMenu);

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
    setShowMenu(false);
  };

  useEffect(() => {
    if (showMenu !== pShowMenu && showMenu === true) {
      let iconRect:any = iconRef.current?.getBoundingClientRect();
      let menuRect:any = optionRef.current?.getBoundingClientRect();

      setTop(iconRect.y);
      setRight(iconRect.x);

      setHeightOffset(iconRect.height);
      setWidthOffset(iconRect.width);

      setMenuOffset(menuRect.width);

      const position = window.pageYOffset;
      setScrollPosition(position);
    }
  }, [showMenu, pShowMenu, iconRef]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={styles.ContextMenu}>
      <div ref={iconRef} onClick={() => { setShowMenu(!showMenu); }} className={styles.innerLabel}>{props.label !== undefined && <div className={styles.labelWrap}>{props.label}</div>}<div className={styles.icon}></div></div>
      {showMenu && createPortal(<ul ref={optionRef} style={{ top: ((top + heightOffset) + scrollPosition) + "px", left: ((right + widthOffset) - menuOffset) + "px" }} className={styles.ContextMenuOptions} onMouseLeave={() => { setShowMenu(false); }}>
        {props.options.map((option:IContextMenuOption, index:number) => {
          return(<li key={index} className={styles.option} onClick={(e) => { option.onClick(e); setShowMenu(false); }}>{option.label}</li>);
        })}
      </ul>, document.body)}
    </div>
  );
};

export default ContextMenu;