import React, { useRef, useState } from "react";
import Button from '../Button/Button';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import Checkbox from "src/components/Checkbox/Checkbox";

import "./SendMessage.scss";

interface IProps {
	handleSendMessage: (message:string, attach_final_confirmation: boolean) => void
}

const SendMessage: React.FC<IProps> = (props) => {
	const editorRef = useRef<TinyMCEEditor | null>(null);
	const [attachFinalConfirmation, setAttachFinalConfirmation] = useState<boolean>(false);
	
    return (
        <form className='SendMessage' onSubmit={(e) => { e.preventDefault(); if (editorRef.current) props.handleSendMessage(editorRef.current.getContent(), attachFinalConfirmation); }}>
			<div className="title">Send Message</div>
			<div className="formWrap">
				<Editor 
				apiKey='e4kmr66e3tqyrdxqk84c9ucybc5l3qzaum53urpf5q3ccg54'
				onInit={(evt, editor) => editorRef.current = editor}
				init={{
					plugins: 'emoticons link lists media searchreplace table visualblocks wordcount',
					toolbar: 'undo redo | fontfamily fontsize | bold italic underline strikethrough | link image mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
					tinycomments_mode: 'embedded',
					tinycomments_author: 'Author name',
					menubar: false,
					branding: false,
					mergetags_list: [
					{ value: 'First.Name', title: 'First Name' },
					{ value: 'Email', title: 'Email' },
					],
				}}
				initialValue=""
				/>
				<div className="checkWrap">
					<Checkbox id={"attachFinalConfirmation"} onChange={() => { setAttachFinalConfirmation(!attachFinalConfirmation); }} value={attachFinalConfirmation} label={"Attach Registrants Final Confirmation to Email"} />
				</div>
			</div>
			<div className="actions">
				<Button type="submit">Send Message</Button>
			</div>
		</form>
      );
}

export default SendMessage;