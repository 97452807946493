import React, { useEffect, useState, useMemo } from "react";
import { Constants } from "@eagerdog/constants";
import { ScentworkScoreDto, ScentworkFaultDto } from "@eagerdog/interfaces";

import Input from "src/components/Input/Input";
import Dropdown, { IOption } from "src/components/Dropdown/Dropdown";
import StopWatch from "../StopWatch/StopWatch";

import styles from "./ScentworkResults.module.scss";

interface IProps {
  result: any,
  onChange(result: ScentworkScoreDto): void
}

const ScentworkResults: React.FC<IProps> = (props) => {
  const nqReasons:IOption[] = [
    { id: Constants.scentwork_nq_reason.incorrect_alert_finish_call, value: Constants.scentwork_nq_reason.incorrect_alert_finish_call },
    { id: Constants.scentwork_nq_reason.harsh_correction, value: Constants.scentwork_nq_reason.harsh_correction },
    { id: Constants.scentwork_nq_reason.significant_disruption_of_area, value: Constants.scentwork_nq_reason.significant_disruption_of_area },
    { id: Constants.scentwork_nq_reason.handler_unable_to_point_to_hide, value: Constants.scentwork_nq_reason.handler_unable_to_point_to_hide },
    { id: Constants.scentwork_nq_reason.max_time_exceeded, value: Constants.scentwork_nq_reason.max_time_exceeded }
  ];

  const excusedReasons:IOption[] = [
    { id: Constants.scentwork_excused_reason.dog_eliminated_in_search_area, value: Constants.scentwork_excused_reason.dog_eliminated_in_search_area },
    { id: Constants.scentwork_excused_reason.dog_overly_stressed, value: Constants.scentwork_excused_reason.dog_overly_stressed },
    { id: Constants.scentwork_excused_reason.handler_request, value: Constants.scentwork_excused_reason.handler_request },
    { id: Constants.scentwork_excused_reason.other, value: Constants.scentwork_excused_reason.other }
  ];

  const getOptionLabel = (id: string) => {
    switch (id) {
      case Constants.score_result_type.qualified:
        return "Qualified";
      case Constants.score_result_type.not_qualified:
        return "Not Qualified";
      case Constants.score_result_type.disqualified:
        return "Disqualified";
      case Constants.score_result_type.excused:
        return "Excused";
      case Constants.score_result_type.absent:
        return "Absent";
    }

    return "None";
  }

  const getFault = (faultType: string) => {
    if (props.result.scentwork?.faults) {
      let hasFault = props.result.scentwork.faults.filter((f: ScentworkFaultDto) => { return f.fault_type === faultType; });

      if (hasFault.length > 0) {
        return hasFault[0].fault_count.toString();
      }
    }

    return "0";
  }

  const requiresNqReason = useMemo(() => {
    return [
      Constants.score_result_type.not_qualified,
      Constants.score_result_type.disqualified
    ]
  }, []);

  const requiresExcusedReason = useMemo(() => {
    return [
      Constants.score_result_type.excused
    ]
  }, []);

  const [result, setResult] = useState<IOption>({ id: props.result.scentwork?.result_type ? props.result.scentwork?.result_type : "None", value: props.result.scentwork?.result_type ? getOptionLabel(props.result.scentwork?.result_type) : "None" });
  const [timeLimits, setTimeLimits] = useState<string>(props.result.scentwork?.time_limit ? props.result.scentwork?.time_limit.toString() : "");
  const [searchTime, setSearchTime] = useState<string>(props.result.scentwork?.search_time ? props.result.scentwork?.search_time.toString() : "");
  const [numHides, setNumHides] = useState<string>(props.result.scentwork?.num_hides ? props.result.scentwork?.num_hides.toString() : "0");
  const [numFinds, setNumFinds] = useState<string>(props.result.scentwork?.num_finds ? props.result.scentwork?.num_finds.toString() : "0");

  const [handlerError, setHandlerError] = useState<string>(getFault(Constants.scentwork_fault_type.handler_error));
  const [safetyConcern, setSafetyConcern] = useState<string>(getFault(Constants.scentwork_fault_type.safety_concern));
  const [mdoa, setMdoa] = useState<string>(getFault(Constants.scentwork_fault_type.mild_disruption_of_area));

  const [nqReason, setNqReason] = useState<IOption>(props.result.scentwork?.result_reason ? { id: props.result.scentwork.result_reason || "", value: props.result.scentwork.result_reason || "" } : { id: Constants.scentwork_nq_reason.incorrect_alert_finish_call, value: Constants.scentwork_nq_reason.incorrect_alert_finish_call });
  const [excusedReason, setExcusedReason] = useState<IOption>(props.result.scentwork?.result_reason ? { id: props.result.scentwork.result_reason || "", value: props.result.scentwork.result_reason || "" } : { id: Constants.scentwork_excused_reason.dog_eliminated_in_search_area, value: Constants.scentwork_excused_reason.dog_eliminated_in_search_area });
  const [otherReason, setOtherReason] = useState<string>(props.result.scentwork?.result_other_text ? props.result.scentwork?.result_other_text : "");

  const resultOptions:IOption[] = [
    { id: "None", value: "None" },
    { id: Constants.score_result_type.qualified, value: "Qualified" },
    { id: Constants.score_result_type.not_qualified, value: "Not Qualified" },
    { id: Constants.score_result_type.disqualified, value: "Disqualified" },
    { id: Constants.score_result_type.excused, value: "Excused" },
    { id: Constants.score_result_type.absent, value: "Absent" }
  ]

  const [hasChanged, setHasChanged] = useState<boolean>(false);

  useEffect(() => {
    if (hasChanged) {
      let _payload: ScentworkScoreDto = {
        time_limit: timeLimits,
        num_hides: Number(numHides),
        num_finds: Number(numFinds),
        faults: [
          { fault_type: Constants.scentwork_fault_type.handler_error, fault_count: Number(handlerError) },
          { fault_type: Constants.scentwork_fault_type.safety_concern, fault_count: Number(safetyConcern) },
          { fault_type: Constants.scentwork_fault_type.mild_disruption_of_area, fault_count: Number(mdoa) }
        ],
        result_type: result.id,
        search_time: searchTime,
      };

      if (requiresNqReason.includes(result.id)) {
        _payload.result_reason = nqReason.id;
      }

      if (requiresExcusedReason.includes(result.id) && excusedReason.id !== Constants.scentwork_excused_reason.other) {
        _payload.result_reason = excusedReason.id;
      }

      if (requiresExcusedReason.includes(result.id) && excusedReason.id === Constants.scentwork_excused_reason.other) {
        _payload.result_reason = excusedReason.id;
        _payload.result_other_text = otherReason;
      }

      props.onChange(_payload);

      setHasChanged(false);
    }
  }, [hasChanged, props, searchTime, result.id, excusedReason.id, handlerError, mdoa, nqReason.id, numFinds, numHides, otherReason, requiresExcusedReason, requiresNqReason, safetyConcern, timeLimits]);

  return (
    <div className={styles.ScentworkResults}>
      <div className={styles.row}><Dropdown value={result} onChange={(e: any, value: IOption) => { setResult(value); setHasChanged(true); }} label="Result" options={resultOptions} placeholder="" /></div>
      {requiresNqReason.includes(result.id) && 
        <div className="reason">
          <Dropdown value={nqReason} label={"NQ Reason"} onChange={(e: any, value: IOption) => { setNqReason(value); setHasChanged(true); }} options={nqReasons} placeholder="" />
        </div>
      }
      {requiresExcusedReason.includes(result.id) && 
        <>
        <div className="reason">
          <Dropdown value={excusedReason} label={"Excused Reason"} onChange={(e: any, value: IOption) => { setExcusedReason(value); setHasChanged(true); }} options={excusedReasons} placeholder="" />
        </div>
        {excusedReason.id === Constants.scentwork_excused_reason.other &&
          <div className={styles.row}><Input label="Other Reason" id="otherReason" type="text" required onChange={(e) => { e.stopPropagation(); setOtherReason(e.target.value); setHasChanged(true); }} placeholder="" defaultValue={otherReason} /></div>
        }
        </>
      }
      <div className={styles.row}><Input label="Time Limit(s)" id="timeLimits" type="text" required onChange={(e) => { e.stopPropagation(); setTimeLimits(e.target.value); setHasChanged(true); }} placeholder="" defaultValue={timeLimits} /></div>
      <div className={styles.row}><StopWatch label={"Run Time"} time={searchTime} onChange={(value: string) => { setSearchTime(value); setHasChanged(true); }} /></div>
      <div className={styles.row}><Input label="# of Hides" id="numHides" type="number" min="0" required onChange={(e) => { e.stopPropagation(); setNumHides(e.target.value); setHasChanged(true); }} placeholder="" defaultValue={numHides} /></div>
      <div className={styles.row}><Input label="# of Finds" id="numFinds" type="number" min="0" required onChange={(e) => { e.stopPropagation(); setNumFinds(e.target.value); setHasChanged(true); }} placeholder="" defaultValue={numFinds} /></div>
      <div className={styles.subTitle}>Faults</div>
      <div className={styles.row}><Input label="Handler Error" id="handlerError" type="number" min="0" required onChange={(e) => { e.stopPropagation(); setHandlerError(e.target.value); setHasChanged(true); }} placeholder="" defaultValue={handlerError} /></div>
      <div className={styles.row}><Input label="Safety Concern" id="safetyConcern" type="number" min="0" required onChange={(e) => { e.stopPropagation(); setSafetyConcern(e.target.value); setHasChanged(true); }} placeholder="" defaultValue={safetyConcern} /></div>
      <div className={styles.row}><Input label="Mild Disruption of Area" id="mdoa" type="number" min="0" required onChange={(e) => { e.stopPropagation(); setMdoa(e.target.value); setHasChanged(true); }} placeholder="" defaultValue={mdoa} /></div>
    </div>
  );
};

export default ScentworkResults;