import React, { useEffect, useState } from "react";
import { Constants } from "@eagerdog/constants";
import { RatRaceScoreDto } from "@eagerdog/interfaces";

import Input from "src/components/Input/Input";
import Dropdown, { IOption } from "src/components/Dropdown/Dropdown";
import StopWatch, { convertTimeToSeconds, convertTimeToMinutes } from "../StopWatch/StopWatch";

import "./CountryRatRaceResults.scss";

interface IProps {
  result: any,
  onChange(result: RatRaceScoreDto): void
}

const CountryRatRaceResults: React.FC<IProps> = (props) => {

  const getOptionLabel = (id: string) => {
    switch (id) {
      case Constants.score_result_type.qualified:
        return "Qualified";
      case Constants.score_result_type.not_qualified:
        return "Not Qualified";
      case Constants.score_result_type.disqualified:
        return "Disqualified";
      case Constants.score_result_type.excused:
        return "Excused";
      case Constants.score_result_type.absent:
        return "Absent";
    }

    return "None";
  }

  const [result, setResult] = useState<IOption>({ id: props.result.country_rat_race?.result_type ? props.result.country_rat_race?.result_type : "None", value: props.result.country_rat_race?.result_type ? getOptionLabel(props.result.country_rat_race?.result_type) : "None" });
  const [time, setTime] = useState<string>(props.result.country_rat_race?.time ? convertTimeToMinutes(props.result.country_rat_race?.time.toString()) : "");
  const [faultCount, setFaultCount] = useState<string>(props.result.country_rat_race?.fault_count ? props.result.country_rat_race?.fault_count.toString() : "0");
  const [ratsFound, setRatsFound] = useState<string>(props.result.country_rat_race?.rats_found ? props.result.country_rat_race?.rats_found.toString() : "0");

  const resultOptions:IOption[] = [
    { id: "None", value: "None" },
    { id: Constants.score_result_type.qualified, value: "Qualified" },
    { id: Constants.score_result_type.not_qualified, value: "Not Qualified" },
    { id: Constants.score_result_type.disqualified, value: "Disqualified" },
    { id: Constants.score_result_type.excused, value: "Excused" },
    { id: Constants.score_result_type.absent, value: "Absent" }
  ]

  const [hasChanged, setHasChanged] = useState<boolean>(false);

  useEffect(() => {
    if (hasChanged) {
      props.onChange({
        result_type: result.id,
        time: convertTimeToSeconds(time),
        fault_count: Number(faultCount),
        rats_found: Number(ratsFound)
      });

      setHasChanged(false);
    }
  }, [hasChanged, props, faultCount, ratsFound, time, result.id]);

  return (
    <div className="CountryRatRaceResults">
      <div className="row"><Dropdown value={result} onChange={(e: any, value: IOption) => { setResult(value); setHasChanged(true); }} label="Result" options={resultOptions} placeholder="" /></div>
      <div className="row"><StopWatch time={time} onChange={(value: string) => { setTime(value); setHasChanged(true); }} /></div>
      <div className="row"><Input label="Fault Count" id="faultCount" type="number" min="0" required onChange={(e) => { e.stopPropagation(); setFaultCount(e.target.value); setHasChanged(true); }} placeholder="" defaultValue={faultCount} /></div>
      <div className="row"><Input label="Rats Found (Fantastic Five, Tempting Ten Only)" id="ratsFound" type="number" min="0" required onChange={(e) => { e.stopPropagation(); setRatsFound(e.target.value); setHasChanged(true); }} placeholder="" defaultValue={ratsFound} /></div>
    </div>
  );
};

export default CountryRatRaceResults;