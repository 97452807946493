import React from "react";
import { Constants } from "@eagerdog/constants";
import moment from "moment-timezone";

import { IDog } from "@eagerdog/interfaces";
import { IShowDogs, IDogInfo } from "../EventRegister";

import { toast } from "../../../components/Toast/ToastManager";
import DogPortrait from "../../../components/DogPortrait/DogPortrait";

import "./ShowTime.scss";

interface IProps {
  show: IShowDogs,
  className?: string,
  onToggleShow(): void,
  onAddDog(): void,
  onEditDog?(): void,
  onDogClick(dog: IDog): void,
  dogs: IDogInfo[],
  onBehalf?: boolean,
  isClaimed?: boolean
}

const ShowTime: React.FC<IProps> = (props) => {
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const showHasDog = (dog:IDogInfo) => {
    for (let d in props.show.dogs) {
      if (props.show.dogs[d]._id === dog._id) {
        return true;
      }
    }

    return false;
  }

  const registrationClosed = () => {
    return props.onBehalf !== true && props.show.use_registration_end_date && moment(props.show.registration_end_date).format("X") <= moment(new Date()).format("X");
  }

  const getRemainingSpots = () => {
    if (props.show.max_dogs) {
      return ((props.show.max_dogs || 0) - (props.show.registered_dogs || 0));
    }

    return 0;
  }

  const hasAvailableSpot = () => {
    if (getRemainingSpots() > 0) {
      return true;
    }

    return false;
  }

  const getPrecisionCourseName = () => {
    if (props.show.show_type === Constants.show_type.precision_coursing) {
      let hasDash = props.show.show_elements.filter((s: any) => { return s.level === Constants.dog_class_element_level.dash });
      let hasSteeplechase = props.show.show_elements.filter((s: any) => { return s.level === Constants.dog_class_element_level.steeplechase });

      if (hasDash.length !== 0 && hasSteeplechase.length === 0) {
        return " ("+ Constants.dog_class_element_level.dash +")";
      }

      if (hasDash.length === 0 && hasSteeplechase.length !== 0) {
        return " ("+ Constants.dog_class_element_level.steeplechase +")";
      }
    }

    return "";
  }

  return (
    <div className={registrationClosed() ? "ShowTime closed " + props?.className : "ShowTime " + props?.className}>
        <div className="showTitle">
          <div className="showText">
            <span className="title" onClick={(e) => { if (!registrationClosed()) { props.onToggleShow(); }}}>{props.show.show_name}{getPrecisionCourseName()} {props.show.max_dogs !== undefined && <span>{getRemainingSpots()} Spots Remaining</span>} {registrationClosed() && <span>Registration Closed</span>}</span>
            <span className="time">{props.show.trailing_show ? "Follows " + props.show.previous_show_name + " (" + moment(props.show.show_date).format("MMM Do") + ")" : moment(props.show.show_date).tz(timeZoneString).format("dddd MMMM Do, YYYY @ h:mmA zz")}</span>
          </div>
          <div className="showActions">
            <ul className="selectedDogs">
              {props.show.dogs.map((dog:IDogInfo, dogIndex:number) => {
                return(
                  <li key={dogIndex}>
                    <div className="dogPortrait"><DogPortrait edit={true} show={props.show} onEditDog={props.onEditDog ? props.onEditDog : undefined} showName={true} dog={dog} /></div>
                  </li>
                );
              })}
            </ul>
            {!registrationClosed() && <div onClick={(e) => { if (!registrationClosed()) { props.onToggleShow(); }}} className="icon plus"></div>}
          </div>
        </div>
        <ul className="dogs">
          <li className="addDog" onClick={props.onAddDog}>
            <div className="iconWrap">
              <div className="icon plus"></div>
            </div>
            <span>New Dog</span>
          </li>
          {props.dogs.map((dog:IDogInfo, di:number) => {
            return(
              <li className={showHasDog(dog) ? "dog selected" : "dog"} onClick={() => {
                if (hasAvailableSpot() || props.show.max_dogs === undefined) {
                  props.onDogClick(dog);
                } else {
                  toast.show({
                    title: "Dog Registration",
                    content: "There aren't any available spots for this show",
                    duration: 10000,
                    type: "fail"
                  });
                }
                
              }
              } key={di}>
                <DogPortrait edit={true} show={props.show} onEditDog={props.onEditDog ? props.onEditDog : undefined} showName={true} dog={dog} />
              </li>
            );
          })}
        </ul>
    </div>
  );
};

export default ShowTime;
