import React, { useState, useEffect, useCallback } from "react";
import { IShow } from "@eagerdog/interfaces";
import { Constants } from "@eagerdog/constants";

import { helperService } from "src/services/helper.service";

import Input from "src/components/Input/Input";
import DatePick from "src/components/DatePick/DatePick";
import Checkbox, { ICheck } from "src/components/Checkbox/Checkbox";

interface IProps {
  show?: IShow,
  currency?: string,
  showType: string,
  showElements: ICheck[],
  onChange(prices: IPrice[], useSamePrice: boolean, useEarlyBird: boolean, earlyBirdEndDate: any, maxDog: string): void
}

export interface IPrice {
  judge_element: string,
  fee: string,
  early_bird_fee?: string
}

const PriceTab: React.FC<IProps> = (props) => {
  const [render, setRender] = useState<boolean>(true);

  const checkForPreviousPrices = useCallback(() => {
    let _prices:IPrice[] = [];
    if (props.show) {
      if (props.show.use_same_fee) {
        let _price:IPrice = {
          judge_element: "",
          fee: props.show.fee.toString()
        };

        if (props.show.use_early_bird_fee && props.show.early_bird_fee) {
          _price.early_bird_fee = props.show.early_bird_fee.toString();
        }

        _prices.push(_price);

        return _prices;
      } else {
        for (let s in props.show.show_elements) {
          let _element:any = props.show.show_elements[s];

          let _price:IPrice = {
            judge_element: _element.level ? _element.level : _element.show_element,
            fee: _element.fee
          };

          if (_element.early_bird_fee !== undefined) {
            _price.early_bird_fee = _element.early_bird_fee;
          }

          _prices.push(_price);
        }

        return _prices;
      }
    } else {
      let _price:IPrice = {
        judge_element: "",
        fee: ""
      };

      _prices.push(_price);

      return _prices;
    }
  }, [props.show]);

  const [useSamePrice, setUseSamePrice] = useState<boolean>(props.show ? props.show.use_same_fee : true);
  const [prices, setPrices] = useState<IPrice[]>(checkForPreviousPrices());
  const [maxDog, setMaxDog] = useState<string>(props.show ? props.show.max_dogs ? props.show.max_dogs.toString() : "" : "");

  const [useEarlyBird, setUseEarlyBird] = useState<boolean>(props.show ? props.show.use_early_bird_fee : false);

  const earlyDate = new Date();
  earlyDate.setMinutes(0);

  const [earlyBirdEndDate, setEarlyBirdEndDate] = useState<Date>(earlyDate);

  const multiPrices:string[] = [
    Constants.show_type.conformation,
    Constants.show_type.scentwork,
    Constants.show_type.shed_dog
  ];

  const updatePrice = (priceInfo: any, index: number) => {
    let _prices:IPrice[] = [...prices];
    _prices[index] = { ..._prices[index], ...priceInfo };

    setPrices(_prices);
  }

  const showElementIsChecked = (judge_element: string) => {
    let hasShowElement:ICheck[] = props.showElements.filter((s: ICheck) => { return s.label === judge_element && s.checked; });
    return hasShowElement.length > 0 ? true : false;
  }

  const pUseSamePrice = helperService.usePrevious(useSamePrice);
  const pShowElements = helperService.usePrevious(props.showElements);

  useEffect(() => {
    const hydratePrices = (elements?: any) => {
      let _prices:IPrice[] = [];

      if (elements) {
        for (let s in props.showElements) {
          let hasElement = elements.filter((e: any) => { return e.level === props.showElements[s].label || e.show_element === props.showElements[s].label; });

          if (hasElement.length <= 0) {
            if (props.showElements[s].checked === true) {
              _prices.push({
                judge_element: props.showElements[s].label,
                fee: ""
              });
            }
          } else {
            _prices.push({
              judge_element: hasElement[0].level ? hasElement[0].level : hasElement[0].show_element,
              fee: ""
            });
          }
        }
      } else {
        for (let s in props.showElements) {
          _prices.push({ judge_element: props.showElements[s].label, fee: "" });
        }
      }

      setPrices(_prices);
    }

    if ((pUseSamePrice !== useSamePrice) || (props.showElements !== pShowElements)) {
      if (useSamePrice) {
        setPrices(checkForPreviousPrices());
      } else {
        hydratePrices(props.show?.show_elements ? props.show.show_elements : undefined);
      }

      setRender(false);
    }
  }, [useSamePrice, pUseSamePrice, props.showElements, pShowElements, checkForPreviousPrices, props.show?.show_elements]);

  useEffect(() => {
    if (!render) {
      setRender(true);
    }
  }, [render]);

  let { onChange } = props;

  useEffect(() => {
    onChange(prices, useSamePrice, useEarlyBird, earlyBirdEndDate, maxDog);
  }, [prices, useSamePrice, onChange, useEarlyBird, earlyBirdEndDate, maxDog]);

  return (
    <div>
      <div className="stepTitle">Price</div>
      <p>What is the price of this show?</p>
      <>{multiPrices.includes(props.showType) && <div className="judgeCheckWrap"><Checkbox id="samePrice" label="Use the same price for all shows" value={useSamePrice} onChange={(e:any) => { setUseSamePrice(!useSamePrice); }} /></div>}</>
      {render && prices.map((p: IPrice, index: number) => {
        if (p.judge_element.length === 0 || showElementIsChecked(p.judge_element)) {
          return(
            <div key={"price" + index} className="inputwrap">
              <Input type="text" label={p.judge_element + " Price (" + (props.currency?.toUpperCase() || "USD") + ")"} onChange={(e) => { updatePrice({ fee: e.target.value }, index); }} placeholder={"What's the price of your "+ p.judge_element +" show?"} defaultValue={p.fee} />
            </div>
          );
        } else {
          return <div key={index}></div>;
        }
      })}
      <Checkbox onChange={(e:any) => { setUseEarlyBird(!useEarlyBird); }} value={useEarlyBird} id="earlyBird" label="Use Early Bird Prices" />
      <>{useEarlyBird &&
        <div className="earlyBird">
          <p>What day do early bird prices end?</p>
          <DatePick label="Early Bird End Date" onChange={setEarlyBirdEndDate} value={earlyBirdEndDate} showTimeSelect={true} />
          {useSamePrice && <Input required label={"Early Bird Price (" + (props.currency?.toUpperCase() || "USD") + ")"} id="earlyBirdPrice" type="text" onChange={(e) => { e.stopPropagation(); updatePrice({ early_bird_fee: e.target.value }, 0); }} placeholder="What's the early bird price?" />}
          <>{props.showType === Constants.show_type.conformation && !useSamePrice && <div className="nonLicensedPrices">
            {prices.map((p: IPrice, index:number) => {
              if (showElementIsChecked(p.judge_element)) {
                return(
                  <div key={"price" + index} className="inputwrap">
                    <Input type="text" label={p.judge_element + " Early Bird Price (" + (props.currency?.toUpperCase() || "USD") + ")"} onChange={(e) => { updatePrice({ early_bird_fee: e.target.value }, index); }} placeholder={"What's the early bird price of your "+ p.judge_element +" show?"} defaultValue={p.early_bird_fee} />
                  </div>
                );
              } else {
                return <div key={index}></div>;
              }
            })}
          </div>}</>
        </div>
      }</>
      <>{(props.showType !== Constants.show_type.fast_cat) && <div className="maxDogWrap">
        <Input min={0} label={"Dog Limit (optional)"} id="maxDog" type="number" onChange={(e:any) => { e.stopPropagation(); setMaxDog(e.target.value); }} placeholder="What's the maximum amount of dog registrants?" defaultValue={maxDog} />
        <p>You can set an overall dog limit for your show, this works well for smaller shows. For larger shows, you may want to leave this empty and set entry limits on a per element basis on the waitlist tab.</p>
      </div>}</>
    </div>
  );
};

export default PriceTab;