import React, { useState, useEffect } from "react";
import { IShow } from "@eagerdog/interfaces";
import { ShowRunTimeSlotDtoId } from "src/components/StepForm/ShowForm/ShowForm";

import { apiService } from "src/services";

import Timeslots from "src/components/Timeslots/Timeslots";

interface IProps {
  show?: IShow,
  onChange(timeslots: ShowRunTimeSlotDtoId[], original_timeslots: ShowRunTimeSlotDtoId[]): void
}

const TimeslotTab: React.FC<IProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  const [originalTimeslots, setOriginalTimeslots] = useState<ShowRunTimeSlotDtoId[]>([]);

  const [timeslots, setTimeslots] = useState<ShowRunTimeSlotDtoId[]>([{
    time_slot: "",
    num_available: 0,
    note: ""
  }]);

  useEffect(() => {
    if (!loaded) {
      const getTimeslots = () => {
        return new Promise((resolve) => {
          if (props.show) {
            apiService.getShowRunTimeSlots(props.show.event_id, props.show._id).then((response) => {
              let _time_slots:ShowRunTimeSlotDtoId[] = [];

              for (let i in response) {
                _time_slots.push({
                  id: response[i]._id,
                  time_slot: response[i].time_slot,
                  num_available: response[i].num_available,
                  note: response[i].note ? response[i].note : "",
                });
              }

              resolve(_time_slots);
            }).catch(() => {
              resolve([]);
            });            
          } else {
            resolve([]);
          }
        });
      }

      const loadTimeslots = async() => {
        const _timeslots:any = await getTimeslots();
        setOriginalTimeslots(_timeslots);
        setTimeslots(_timeslots);
        setLoaded(true)
      }
      
      loadTimeslots();
    }
  }, [loaded, props.show]);

  const { onChange } = props;

  useEffect(() => {
    onChange(timeslots, originalTimeslots);
  }, [timeslots, originalTimeslots, onChange]);

  return (
    <div>
      <div className="stepTitle">Run Times</div>
      <p>Create timeslots for your attendees to select when registering. Include a label, total amount of dogs that can attend during that timeslot and an optional note for the attendees about the timeslot.</p>
      {loaded && <Timeslots timeslots={timeslots} onChange={(timeslots: ShowRunTimeSlotDtoId[]) => {
        setTimeslots(timeslots);
      }} />}
    </div>
  );
};

export default TimeslotTab;