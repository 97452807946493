import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { AxiosError } from "axios";
import { IScore, IEvent, IDogClass, /*IShow, DogShowClassesMoveUpDto*/ } from "@eagerdog/interfaces";

import { Nosework } from "src/components/FormBuilder/forms/Nosework";
import { RallyObedience } from "src/components/FormBuilder/forms/RallyObedience";
import { Obedience } from "src/components/FormBuilder/forms/Obedience";
import { Constants } from "@eagerdog/constants";

import { apiService } from "src/services/api.service";

import Button from "src/components/Button/Button";
import Dropdown, { IOption } from "src/components/Dropdown/Dropdown";
import { toast } from "src/components/Toast/ToastManager";

import "./MoveUp.scss";

interface IProps {
  event: IEvent | undefined,
  registration: IScore,
  onComplete(): void,
  onClose(): void
}

interface IDogClassMoveUp extends IDogClass {
  new_level?: string
}

interface IScoreMoveUp extends IScore {
  dog_class: IDogClassMoveUp
}

interface IMoveUpShow {
  show_id: string,
  show_name: string,
  scores: IScoreMoveUp[]
}

interface IElementOptions { 
  [key: string]: IOption[];
}

const MoveUp: React.FC<IProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  const [moveUpShows, setMoveUpShows] = useState<IMoveUpShow[]>([]);
  const [showOptions, setShowOptions] = useState<IElementOptions>();

  const findNextLevel = useCallback((currentLevel: string, currentElement: string, elementOptions?: IElementOptions) => {
    let options:IElementOptions = elementOptions ? elementOptions : showOptions || {};

    if (options[currentElement]) {
      for (let i in options[currentElement]) {
        if (options[currentElement][i].id === currentLevel) {
          let index:number = options[currentElement].indexOf(options[currentElement][i]);

          if (index + 1 >= options[currentElement].length) {
            return options[currentElement][i].id;
          }

          return options[currentElement][index].id;
        }
      }
    }

    return currentLevel;
  }, [showOptions]);

  const populateOptions = useCallback(() => {
    let options:IElementOptions = {};

    let form:any = Nosework.form;

    switch (props.registration.show_type) {
      case Constants.show_type.rally_obedience:
        form = RallyObedience.form;
      break;
      case Constants.show_type.obedience:
        form = Obedience.form;
      break;
      default:
        form = Nosework.form;
      break;
    }

    for (let row in form) {
      for (let input in form[row].inputs) {
        if (form[row].inputs[input].label !== "Section") {
          let _options:IOption[] = JSON.parse(JSON.stringify(form[row].inputs[input].options));
          _options.shift();

          options[form[row].inputs[input].label] = _options;
        }
      }
    }

    setShowOptions(options);
    return options;
  }, [props.registration.show_type]);

  const handleNewLevelChange = (value: IOption, scoreId: string) => {
    let _moveUpShows = JSON.parse(JSON.stringify(moveUpShows));

    for (let s in _moveUpShows) {
      let findScore = _moveUpShows[s].scores.filter((score: IScoreMoveUp, index: number) => { return score._id === scoreId; });

      if (findScore.length > 0) {
        let index:number = _moveUpShows[s].scores.indexOf(findScore[0]);
        _moveUpShows[s].scores[index].dog_class.new_level = value.id;
      }
    }

    setMoveUpShows(_moveUpShows);
  }

  const submitMoveUp = () => {
    if (props.event) {
      let _payload:any = {
        dog_classes: []
      }

      let _moveUpShows = JSON.parse(JSON.stringify(moveUpShows));

      for (let show in _moveUpShows) {
        for (let score in _moveUpShows[show].scores) {
          let _old_dog_class: any = _moveUpShows[show].scores[score].dog_class;

          let _dog_class:any = {
            score_id: _moveUpShows[show].scores[score]._id,
            dog_class: {
              section: _old_dog_class.section,
              show_element: _old_dog_class.show_element,
              level: _old_dog_class.new_level
            }
          };

          _payload.dog_classes.push(_dog_class);
        }
      }

      _payload.dog_classes = _payload.dog_classes.reverse();

      apiService.dogClassMoveUp(props.event._id, _payload).then((response) => {
        if (response === undefined) {
          toast.show({
            title: "Move Up",
            content: "Move up for " + props.registration.call_name + " successful",
            duration: 10000,
            type: "success"
          });

          props.onClose();
        }
      }).catch((e: any) => {
        toast.show({
          title: "Move up",
          content: e.response?.data?.message ? e.response.data.message : "Failed to move up " + props.registration.call_name,
          duration: 10000,
          errorDetails: e,
          type: "fail"
        });
      });

    }
  }

  useEffect(() => {
    if (!loaded) {
    let showOptions:IElementOptions = populateOptions();

      let _query:any = {
        query: {
          "$and": [{
            attribute_name: "dog_id",
            attribute_value: {
              operator: "$eq",
              value: props.registration.dog_id
            }
          }, {
            attribute_name: "dog_class.show_element",
            attribute_value: {
              operator: "$eq",
              value: props.registration.dog_class?.show_element
            }
          }, {
            attribute_name: "_id",
            attribute_value: {
              operator: "$ne",
              value: props.registration._id
            }
        }]
        },
        sort: []
      };

      apiService.getEventScores(props.registration.event_id, _query).then((response) => {
        let _dc:IMoveUpShow[] = [];

        for (let s in response) {
          let hasShow = _dc.filter((dc: any) => dc.show_id === response[s].show_id);
          let _show:any = JSON.parse(JSON.stringify(response[s]));
          _show.dog_class.new_level = findNextLevel(_show.dog_class.level, _show.dog_class.show_element, showOptions);

          if (hasShow.length === 0) {
            _dc.push({
              show_id: _show.show_id,
              show_name: _show.show_type + " " + _show.show_name + " (" + moment(_show.show_date).format("MMM Do") + ")",
              scores: [_show]
            });
          } else {
            hasShow[0].scores.push(_show);
          }
        }

        setMoveUpShows(_dc);
      }).catch((e: AxiosError) => {
        toast.show({
          title: "Get Dog Classes",
          content: "Failed to get Dog Classes",
          duration: 10000,
          errorDetails: e,
          type: "fail"
        });
      });
      
      setLoaded(true);
    }
  }, [loaded, props.registration, findNextLevel, populateOptions]);

  const renderScore = (score:IScoreMoveUp, index:number) => {
    return(
      <div key={index + "score"} className="scoreWrap">
        {score.dog_class && score.dog_class.level &&
          <Dropdown
            value={{id: score.dog_class.level, value: score.dog_class.level}}
            label={"Current Element (" + score.dog_class.show_element + ")"}
            disabled={true}
            onChange={(e, value: IOption) => { }}
            options={[{id: score.dog_class.level, value: score.dog_class.level}]}
            placeholder=""
          />
        }
        <div className="arrowWrap"><div className="icon arrowLeft"></div></div>
        {score.dog_class && score.dog_class.level && 
          <Dropdown 
            value={{id: score.dog_class.new_level || "", value: score.dog_class.new_level || ""}} 
            label={"New Element (" + score.dog_class.show_element + ")"} 
            onChange={(e, value: IOption) => {
              handleNewLevelChange(value, score._id);
            }} 
            options={showOptions && showOptions[score.dog_class.show_element] ? showOptions[score.dog_class.show_element] : []} 
            placeholder="" 
          />
        }
      </div>
      );
  }

  return (
    <form onSubmit={(e) => { e.preventDefault(); submitMoveUp(); }} className="MoveUp">
      <div className="title">
        <span>Move Up</span>
        <span>{props.registration.call_name} #{props.registration.arm_number}</span>
      </div>
      <div className="moveUpContent">
        {loaded && moveUpShows.length === 0 && <div className="empty">{props.registration.call_name} is not in any other shows</div>}
        {moveUpShows.map((show:IMoveUpShow, index:number) => {
          return (
            <div key={index + "show"} className="showWrap">
              <div className="showTitle">{show.show_name}</div>
              {show.scores.map((score:IScoreMoveUp, indext:number) => {
                return(renderScore(score, indext));
              })}
            </div>
          );
        })}
      </div>
      <div className="actions">
        <Button onClick={() => { props.onClose(); }} type="button">Cancel</Button>
        {loaded && moveUpShows.length !== 0 && <Button type="submit">Confirm Changes</Button>}
      </div>
    </form>
  );
};

export default MoveUp;