import React, { useState } from 'react';

import { Modal, useModal } from "src/components/Modal/Modal";
import ContextMenu, { IContextMenuOption } from "src/components/ContextMenu/ContextMenu";
import MoveUp from "src/components/MoveUp/MoveUp";
import EnterResults from "src/components/EnterResults/EnterResults";

import type { CustomCellRendererProps } from 'ag-grid-react';

import styles from "./ResultsTab.module.scss";

interface CustomResultsCellRendererProps extends CustomCellRendererProps {
  getResults(): void
}

export const ActionsRenderer = (props: CustomResultsCellRendererProps) => {
  const [modalContent, setModalContent] = useState<"move" | "enter" | undefined>();

  const { isShown, toggle } = useModal();

  let menuOptions:IContextMenuOption[] = [
    {
      label: "Move Up",
      onClick:() => {
        setModalContent("move");
        toggle();
      }
    }
  ];

  if (props.value.canEnterResults) {
    menuOptions.push({
      label: "Enter Results",
      onClick:() => {
        setModalContent("enter");
        toggle();
      }
    });
  }

  const getModalClassName = () => {
    switch(modalContent) {
      case "move":
        return "moveUpModal";
      case "enter":
        return "enterResultsModal";
    }

    return "";
  }

  const getModalContent = () => {
    switch(modalContent) {
      case "move":
        return <MoveUp
            event={props.value.event}
            registration={props.value.result}
            onComplete={() => {
                toggle();
                setModalContent(undefined);
                props.getResults();
              }
            }
            onClose={() => {
                toggle();
                setModalContent(undefined);
                props.getResults();
              }
            }
          />;
      case "enter":
        return <EnterResults
          eventId={props.value.event._id}
          showType={props.value.result.show_type}
          result={props.value.result}
          onSave={() => {
            toggle();
            setModalContent(undefined);
            props.getResults();
          }}
          onClose={() => {
            toggle();
            setModalContent(undefined);
            props.getResults();
          }}
        />;
    }

    return <></>;
  }

  return (<div className={styles.ActionRenderer}>
    <ContextMenu
      options={menuOptions}
    />
    <Modal
      className={getModalClassName()}
      isShown={isShown}
      hide={toggle}
      modalContent={getModalContent()}
    />
  </div>);
};