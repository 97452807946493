import React, { useState, useEffect, RefObject } from "react";
import { helperService } from "src/services/helper.service";

import "./Dropdown.scss";

import checkIcon from "../../assets/icons/check.svg";

export interface IOption {
  value: string,
  id: string,
  disabled?: boolean,
  data?: any
}

interface IProps {
  label: string,
  options: IOption[],
  icon?: string,
  placeholder: string,
  value: IOption,
  onChange(e: any, value: IOption): void
  error?: boolean,
  dropUp?: boolean,
  alignRight?: boolean,
  id?: string,
  disabled?: boolean,
  className?: string
}

export const Provinces:IOption[] = [
  { value: 'Alberta', id: 'AB' },
  { value: 'British Columbia', id: 'BC' },
  { value: 'Manitoba', id: 'MB' },
  { value: 'New Brunswick', id: 'NB' },
  { value: 'Newfoundland and Labrador', id: 'NL' },
  { value: 'Northwest Territories', id: 'NT' },
  { value: 'Nova Scotia', id: 'NS' },
  { value: 'Nunavut', id: 'NU' },
  { value: 'Ontario', id: 'ON' },
  { value: 'Prince Edward Island', id: 'PE' },
  { value: 'Quebec', id: 'QC' },
  { value: 'Saskatchewan', id: 'SK' },
  { value: 'Yukon Territory', id: 'YT' }
];

export const States:IOption[] = [
    { value: 'Alabama', id: 'AL'},
    { value: 'Alaska', id: 'AK'},
    { value: 'American Samoa', id: 'AS'},
    { value: 'Arizona', id: 'AZ'},
    { value: 'Askansas', id: 'AR'},
    { value: 'California', id: 'CA'},
    { value: 'Colorado', id: 'CO'},
    { value: 'Connecticut', id: 'CT'},
    { value: 'Delaware', id: 'DE'},
    { value: 'District of Colombia', id: 'DC'},
    { value: 'Federated states OF Micronesia', id: 'FM'},
    { value: 'Florida', id: 'FL'},
    { value: 'Georgia', id: 'GA'},
    { value: 'Guam', id: 'GU'},
    { value: 'Hawaii', id: 'HI'},
    { value: 'Idaho', id: 'ID'},
    { value: 'Illinos', id: 'IL'},
    { value: 'Indiana', id: 'IN'},
    { value: 'Iowa', id: 'IA'},
    { value: 'Kansas', id: 'KS'},
    { value: 'Kentucky', id: 'KY'},
    { value: 'Louisiana', id: 'LA'},
    { value: 'Maine', id: 'ME'},
    { value: 'Marshall Islands', id: 'MH'},
    { value: 'Maryland', id: 'MD'},
    { value: 'Massachusetts', id: 'MA'},
    { value: 'Michigan', id: 'MI'},
    { value: 'Minnesota', id: 'MN'},
    { value: 'Mississippi', id: 'MS'},
    { value: 'Missouri', id: 'MO'},
    { value: 'Montana', id: 'MT'},
    { value: 'Nebraska', id: 'NE'},
    { value: 'Nevada', id: 'NV'},
    { value: 'New Hampshire', id: 'NH'},
    { value: 'New Jersey', id: 'NJ'},
    { value: 'New Mexico', id: 'NM'},
    { value: 'New York', id: 'NY'},
    { value: 'North Carolina', id: 'NC'},
    { value: 'North Dakota', id: 'ND'},
    { value: 'Northeren Mariana Islands', id: 'MP'},
    { value: 'Ohio', id: 'OH'},
    { value: 'Oklahoma', id: 'OK'},
    { value: 'Oregon', id: 'OR'},
    { value: 'Palau', id: 'PW'},
    { value: 'Pennsylvania', id: 'PA'},
    { value: 'Puerto Rico', id: 'PR'},
    { value: 'Rhode Island', id: 'RI'},
    { value: 'South Carolina', id: 'SC'},
    { value: 'South Dakota', id: 'SD'},
    { value: 'Tennessee', id: 'TN'},
    { value: 'Texas', id: 'TX'},
    { value: 'Utah', id: 'UT'},
    { value: 'Vermont', id: 'VT'},
    { value: 'Virgin Islands', id: 'VI'},
    { value: 'Virginia', id: 'VA'},
    { value: 'Washington', id: 'WA'},
    { value: 'West Virginia', id: 'WV'},
    { value: 'Wisconsin', id: 'WI'},
    { value: 'Wyoming', id: 'WY' }
];

const Dropdown: React.FC<IProps> = (props) => {
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const [selectedValue, setSelectedValue] = useState<IOption>(props.value);

  const selectRef:RefObject<HTMLSelectElement> = React.createRef();
  const divRef:RefObject<HTMLDivElement> = React.createRef();

  const pSelectedValue = helperService.usePrevious(selectedValue);
  const pValue = helperService.usePrevious(props.value);

  useEffect(() => {
    if (pSelectedValue !== selectedValue) {
      const event = new Event("change", { bubbles: true });
      selectRef?.current?.dispatchEvent(event);
    }
  }, [selectedValue, pSelectedValue, selectRef]);

  // Dropdown value being programmatically set, outside of the users input
  useEffect(() => {
    if (pValue) {
      let _pValue = pValue as IOption;

      if (props.value.id !== _pValue.id) {
        setSelectedValue(props.value);
      }
    }
  }, [props.value, selectedValue, pValue]);


  return (
    <div className={(showOptions ? "Dropdown wOptions" : props.error ? "Dropdown error" : "Dropdown") + (props.dropUp ? " dropUp" : "") + (props.alignRight ? " alignRight" : "") + (props.className ? " " + props.className : "")}>
      <label><span title={props.label}>{props.label}</span></label>
      <select disabled={props.disabled ? props.disabled : false} id={props.id ? props.id : ""} onChange={(e:any) => { e.target.value = selectedValue.id; props.onChange(e, selectedValue); }} defaultValue={selectedValue.value} ref={selectRef}>
        <option value={""}>No Value Selected</option>
        {props.options.map((option:IOption, index:number) => {
          return(
            <option disabled={true} key={index} value={option.id}>{option.value}</option>
          );
        })}
      </select>
      <div ref={divRef} className={props.icon ? props.disabled ? "select disabled" : "select" : props.disabled ? "select noIcon disabled": "select noIcon"} onClick={() => { if (!props.disabled) { setShowOptions(!showOptions); } }}>
        {props.icon ? <img className="icon" src={props.icon} alt="Dropdown Icon" /> : null}
        <div className="value">{selectedValue.value}</div>
      </div>
      {showOptions && <div className="options">
        <div className="innerOptions">
          {props.options.map((option:IOption, index:number) => {
            return(
              <div key={index} onClick={(e:any) => { if (!option.disabled) { setSelectedValue(option); setShowOptions(false); } }} className={option.disabled ? "option disabled" : "option"}>
                <div className={selectedValue.value === option.value ? "check" : "check hidden"}>
                  <img src={checkIcon} alt="Selected Option"/>
                </div>
                <span>{option.disabled ? option.value + " (disabled)" : option.value}</span>
              </div>
            );
          })}
        </div>
      </div>}
    </div>
  );
};

export default Dropdown;