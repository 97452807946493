import React, { useState, useEffect, useCallback } from "react";
import { IEvent } from "@eagerdog/interfaces";
import moment from "moment";

import { apiService } from "src/services/api.service";

import ContextMenu, { IContextMenuOption } from "src/components/ContextMenu/ContextMenu";
import { Modal, useModal } from "src/components/Modal/Modal";
import Confirm from "src/components/Confirm/Confirm";
import { toast } from "src/components/Toast/ToastManager";

import type { ColDef } from "ag-grid-community";
import { themeQuartz } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

import { ActionsRenderer } from "./ActionsRenderer";
import { StatusRenderer } from "./StatusRenderer";

import styles from "./WaitlistTab.module.scss";

interface IProps {
  event: IEvent
}

interface IRow {
  callName: string,
  status: string,
  breed: string,
  showName: string,
  showElement: string,
  showLevel: string,
  registeredAt: string,
  actions: any
}

const WaitlistTab: React.FC<IProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [cancelingWaitlists, setCancelingWaitlists] = useState<boolean>(false);

  const { isShown, toggle } = useModal();

  const menuActions:IContextMenuOption[] = [
    {
      label: "Cancel All",
      onClick: () => {
        toggle();
      }
    }
  ];

  const theme = themeQuartz.withParams({
    fontFamily: "Roboto",
    headerFontFamily: "Roboto",
    cellFontFamily: "Roboto"
  });

  const getWaitlists = useCallback((id: string) => {
    let query:any = {
      sort: [{
        attribute_name: "created_at",
        sort: "asc"
      }]
    };

    apiService.getEventWaitlists(id, query).then((response) => {
      let rows:IRow[] = [];

      for (let i in response) {
        let row:any = response[i];

        rows.push({
          callName: row.call_name + " ("+ row.registered_name +")",
          status: row.status,
          breed: row.breed,
          showName: row.show_type + " " + row.show_name,
          showElement: row.dog_class.show_element,
          showLevel: row.dog_class.level || "",
          registeredAt: moment(row.created_at).format("MMM Do YYYY, h:mmA zz"),
          actions: {
            waitlist: row
          }
        });
      }

      setRowData(rows);
    });
  }, []);

  let colDefs:ColDef<IRow>[] = [
    { field: "callName", minWidth: 120, cellDataType: "text", filter: true },
    {
      field: "status",
      minWidth: 150,
      filter: true,
      cellRenderer: StatusRenderer,
    },
    { field: "breed", minWidth: 120, cellDataType: "text", filter: true },
    { field: "showName", minWidth: 120, cellDataType: "text", filter: true },
    { field: "showElement", minWidth: 120, cellDataType: "text", filter: true },
    { field: "showLevel", minWidth: 120, cellDataType: "text", filter: true },
    { field: "registeredAt", minWidth: 120, cellDataType: "text", filter: true },
    {
      headerName: "",
      field: "actions",
      maxWidth: 50,
      minWidth: 50,
      cellRenderer: ActionsRenderer,
      pinned: "right",
      cellRendererParams: {
        getWaitlists: () => { getWaitlists(props.event._id); }
      },
      resizable: false,
      cellStyle: {
        "overflow": "visible",
        "zIndex": "2"
      }
    }
  ];

  const onFirstDataRendered = (params:any) => {
    params.api.setFilterModel({
        "status": {
            "values": [ "waitlist" ],
            "filterType": "set"
        }
    });
  }

  const [rowData, setRowData] = useState<IRow[]>([]);

  const defaultColDef: ColDef = {
    flex: 1
  };

  useEffect(() => {
    if (!loaded) {
      getWaitlists(props.event._id);
      setLoaded(true);
    }
  }, [loaded, getWaitlists, props.event._id]);

  return (
    <div className={styles.WaitlistTab}>
      <div className={styles.actions}>
        <ContextMenu
          label={"Actions"}
          options={menuActions}
        />
      </div>
      <AgGridReact
        rowData={rowData}
        suppressRowTransform={true}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        onFirstDataRendered={onFirstDataRendered}
        theme={theme}
        pagination={true}
        paginationPageSize={20}
      />
    <Modal
      className="confirmModal"
      isShown={isShown}
      hide={toggle}
      modalContent={<Confirm
        message={<>Are you sure you want to cancel all outstanding waitlist registrations for (showtype)?</>}
        buttonsDisabled={cancelingWaitlists}
        onConfirm={() => {
          setCancelingWaitlists(true);

          apiService.cancelEventWaitlistEntries(props.event._id).then((response) => {
            getWaitlists(props.event._id);

            toast.show({
              title: "Waitlists",
              content: "You've successfully canceled all waitlists",
              duration: 10000,
              type: "success"
            });
          }).catch((error:any) => {
            toast.show({
              title: "Waitlists",
              content: error.response.data.message ? error.response.data.message : "Something went wrong, please try again later",
              duration: 10000,
              type: "fail"
            });
          }).finally(() => {
            setCancelingWaitlists(false);
            toggle();
          });
        }}
        onCancel={() => {
          toggle();
        }}
      />}
    />
    </div>
  );
};

export default WaitlistTab;