import React, { useState, useEffect, useCallback } from "react";
import { IEventSummary } from "@eagerdog/interfaces";
import moment from "moment";

import { apiService } from "src/services/api.service";

import ContextMenu, { IContextMenuOption } from "src/components/ContextMenu/ContextMenu";
import { download } from "src/components/Download/DownloadManager";

import type { ColDef } from "ag-grid-community";
import { themeQuartz } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

import { ActionsRenderer } from "./ActionsRenderer";

import styles from "./TransactionsTab.module.scss";

interface IProps {
  event: IEventSummary
}

interface IRow {
  name: string,
  processedAmt: string,
  clubAmt: string,
  refundedAmt: string,
  date: string,
  actions: any
}

const TransactionsTab: React.FC<IProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  const menuActions:IContextMenuOption[] = [
    {
      label: "Export Transactions",
      onClick: () => {
        download.pushDownload({
          type: "transactions",
          eventId: props.event._id
        });
      }
    }
  ];

  const theme = themeQuartz.withParams({
    fontFamily: "Roboto",
    headerFontFamily: "Roboto",
    cellFontFamily: "Roboto"
  });

  const getTransactions = useCallback((id: string) => {
    let query:any = {
      sort: [{
        attribute_name: "show_type",
        sort: "asc"
      }, {
        attribute_name: "created_at",
        sort: "asc"
      }],
      query: {
        $and: [{
          attribute_name: "status",
          attribute_value: {
            operator: "$eq",
            value: "success"
          }
        }]
      }
    };

    apiService.getEventTransactions(id, query).then((response) => {
      let rows:IRow[] = [];

      console.log(response);

      for (let i in response) {
        let row:any = response[i];

        let formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: row.amounts.currency.toUpperCase(),
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });

        rows.push({
          name: row.user.first_name + " " + row.user.last_name,
          processedAmt: formatter.format((row.amounts.processed_total_amount ? row.amounts.processed_total_amount  / 100 : 0) as number),
          clubAmt: formatter.format((row.amounts.processed_amount ? row.amounts.processed_amount  / 100 : 0) as number),
          refundedAmt: formatter.format((row.amounts.refunded_amount ? row.amounts.refunded_amount  / 100 : 0) as number),
          date: moment(row.created_at).format("DD/MM/YYYY @ h:mmA"),
          actions: {
            transaction: row,
            event: props.event
          }
        });
      }

      setRowData(rows);
    });
  }, [props.event]);

  let colDefs:ColDef<IRow>[] = [
    { field: "name", minWidth: 120, filter:true },
    { field: "processedAmt", minWidth: 150, filter:true },
    { field: "clubAmt", minWidth: 150, filter:true },
    { field: "refundedAmt", minWidth: 150, filter: true },
    { field: "date", minWidth: 150, filter: true },
    {
      headerName: "",
      field: "actions",
      maxWidth: 50,
      minWidth: 50,
      cellRenderer: ActionsRenderer,
      pinned: "right",
      cellRendererParams: {
        getTransactions: () => { getTransactions(props.event._id); }
      },
      resizable: false,
      cellStyle: {
        "overflow": "visible",
        "zIndex": "2"
      }
    }
  ];

  const [rowData, setRowData] = useState<IRow[]>([]);

  const defaultColDef: ColDef = {
    flex: 1
  };

  useEffect(() => {
    if (!loaded) {
      getTransactions(props.event._id);
      setLoaded(true);
    }
  }, [loaded, getTransactions, props.event._id]);

  return (
    <div className={styles.TransactionsTab}>
      <div className={styles.actions}>
        <ContextMenu
          label={"Actions"}
          options={menuActions}
        />
      </div>
      <div className={styles.tableWrap}>
        <AgGridReact
          rowData={rowData}
          suppressRowTransform={true}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          theme={theme}
          pagination={true}
          paginationPageSize={20}
        />
      </div>
    </div>
  );
};

export default TransactionsTab;