import React from 'react';
import { Constants } from "@eagerdog/constants";
import { useNavigate } from "react-router-dom";

import { apiService } from "src/services";

import { toast } from "src/components/Toast/ToastManager";
import { Modal, useModal } from "src/components/Modal/Modal";
import Confirm from "src/components/Confirm/Confirm";
import ContextMenu/*, { IContextMenuOption }*/ from "src/components/ContextMenu/ContextMenu";

import type { CustomCellRendererProps } from 'ag-grid-react';

import styles from "./UserWaitlistTab.module.scss";

interface CustomUserWaitlistCellRendererProps extends CustomCellRendererProps {
  getWaitlists(): void
  waitlist: any
}

export const ActionsRenderer = (props: CustomUserWaitlistCellRendererProps) => {
  const navigate = useNavigate();
  const { isShown, toggle } = useModal();

  return (<div className={styles.ActionRenderer}>
    <ContextMenu
      options={props.value.waitlist.status === Constants.waitlist_status.waitlist ? [
        { 
          label: "Withdraw",
          onClick:() => {
            toggle();
          }
        }, { 
          label: "View Event",
          onClick:() => {
            navigate("/e/" + props.value.waitlist.event.handle);
          }
        }
      ] : [{ 
        label: "View Event",
        onClick:() => {
          navigate("/e/" + props.value.waitlist.event.handle);
        }
      }]}
    />

    <Modal
      className="confirmModal"
      isShown={isShown}
      hide={toggle}
      modalContent={<Confirm
        message={<>Are you sure you want to withdraw {props.value.waitlist.call_name} from {props.value.waitlist.show_type} {props.value.waitlist.show_name}? You'll be removed from the waitlist and expenses will be refunded.</>}
        onConfirm={() => {
          apiService.cancelUserWaitlistEntry(props.value.waitlist._id).then((response) => {
            toast.show({
              title: "Waitlist Withdraw",
              content: "You've successfully withdrawn from " + props.value.waitlist.show_type + " " + props.value.waitlist.show_name,
              duration: 10000,
              type: "success"
            });

            props.getWaitlists();
          }).catch((e: any) => {
              toast.show({
                title: "Waitlist Withdraw",
                content: e.response.data.message ? e.response.data.message : "Something went wrong, please try again later",
                duration: 10000,
                type: "fail"
              });
          });

          toggle();
        }}
        onCancel={() => {
          toggle();
        }}
      />}
    />
  </div>);
};
