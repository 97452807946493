import React from 'react';

import type { CustomCellRendererProps } from 'ag-grid-react';

import styles from "./ResultsTab.module.scss";

export const ResultRenderer = (props: CustomCellRendererProps) => {
  return (<div className={styles.ResultRenderer}>
    <div className={`${styles.status} ${styles[props.value]}`}>{props.value}</div>
  </div>);
};