import React, { useEffect, useState } from "react";
import { Constants } from "@eagerdog/constants";
import { ObedienceScoreDto } from "@eagerdog/interfaces";

import Input from "src/components/Input/Input";
import Dropdown, { IOption } from "src/components/Dropdown/Dropdown";

import "./ObedienceResults.scss";

interface IProps {
  result: any,
  onChange(result: ObedienceScoreDto): void
}

const ObedienceResults: React.FC<IProps> = (props) => {
  const getOptionLabel = (id: string) => {
    switch (id) {
      case Constants.score_result_type.qualified:
        return "Qualified";
      case Constants.score_result_type.not_qualified:
        return "Not Qualified";
      case Constants.score_result_type.disqualified:
        return "Disqualified";
      case Constants.score_result_type.excused:
        return "Excused";
      case Constants.score_result_type.absent:
        return "Absent";
    }

    return "None";
  }

  const [deductions, setDeductions] = useState<string>(props.result.obedience?.deductions ? props.result.obedience?.deductions.toString() : "0");
  const [result, setResult] = useState<IOption>({ id: props.result.obedience?.result_type ? props.result.rally_obedience?.result_type : "None", value: props.result.obedience?.result_type ? getOptionLabel(props.result.obedience?.result_type) : "None" });

  const resultOptions:IOption[] = [
    { id: "None", value: "None" },
    { id: Constants.score_result_type.qualified, value: "Qualified" },
    { id: Constants.score_result_type.not_qualified, value: "Not Qualified" },
    { id: Constants.score_result_type.disqualified, value: "Disqualified" },
    { id: Constants.score_result_type.excused, value: "Excused" },
    { id: Constants.score_result_type.absent, value: "Absent" }
  ]

  const [hasChanged, setHasChanged] = useState<boolean>(false);

  useEffect(() => {
    if (hasChanged) {
      props.onChange({
        result_type: result.id,
        deductions: Number(deductions),
      });

      setHasChanged(false);
    }
  }, [hasChanged, props, deductions, result.id]);

  return (
    <div className="ObedienceResults">
      <div className="row"><Dropdown value={result} onChange={(e: any, value: IOption) => { setResult(value); setHasChanged(true); }} label="Result" options={resultOptions} placeholder="" /></div>
      <div className="row"><Input label="Deductions (Total Point Deduction)" id="deductions" type="number" min="0" required onChange={(e) => { e.stopPropagation(); setDeductions(e.target.value); setHasChanged(true); }} placeholder="" defaultValue={deductions} /></div>
    </div>
  );
};

export default ObedienceResults;