import React, { useEffect, useState } from "react";
import { Constants } from "@eagerdog/constants";

import { apiService } from "src/services";

import { toast } from '../Toast/ToastManager';
import { Modal, useModal } from "../Modal/Modal";
import { download, DownloadOptions } from "./DownloadManager";

export interface DownloadProps {
  files: DownloadOptions[]
}

const Download: React.FC<DownloadProps> = (props) => {
  const { isShown, toggle } = useModal();

  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [downloadTimer, setdownloadTimer] = useState<number>(0);

  let downloadInterval: any;

  const requestDownload = (file: DownloadOptions) => { // eslint-disable-line
    switch(file.type) {
      case "judge-scheduling-calc":
        if (file.eventId) {
          apiService.requestEventJudgeScheduleCalculator(file.eventId).then((response) => {
            if (response._id && downloadInterval === undefined) {
              downloadInterval = setInterval(() => { checkCurrentDownload(response) }, 1000);
            }
          });
        }
        break;

      case "catalog":
        if (file.eventId) {
          apiService.requestEventCatalog(file.eventId).then((response) => {
            if (response._id && downloadInterval === undefined) {
              downloadInterval = setInterval(() => { checkCurrentDownload(response) }, 1000);
            }
          });
        }
      break;
      case "registration":
        if (file.registrantId && file.dogId) {
          apiService.requestEntryForms(file.registrantId, file.dogId).then((response) => {
            if (response._id && downloadInterval === undefined) {
              downloadInterval = setInterval(() => { checkCurrentDownload(response) }, 1000);
            }
          });
        }
      break;
      case "judge_books":
        if (file.eventId && file.showId) {
          let params: any = {};

          if (file.showElement && file.showElement !== "None") {
            params.show_element = file.showElement;
          }

          if (file.showLevel && file.showLevel !== "None") {
            params.level = file.showLevel;
          }

          if (file.showSection && file.showSection !== "None") {
            params.section = file.showSection;
          }

          apiService.requestJudgeBooks(file.eventId, file.showId, params).then((response) => {
            if (response._id && downloadInterval === undefined) {
              downloadInterval = setInterval(() => { checkCurrentDownload(response) }, 1000);
            }
          });
        }
      break;
      case "transaction":
        if (file.transactionId) {
          apiService.requestEntryFormsForTransaction(file.transactionId).then((response) => {
            if (response._id && downloadInterval === undefined) {
              downloadInterval = setInterval(() => { checkCurrentDownload(response) }, 1000);
            }
          });
        }
      break;
      case "nwt":
        if (file.eventId && file.showType) {
          apiService.requestScoreListForShowType(file.eventId, file.showType).then((response) => {
            if (response._id && downloadInterval === undefined) {
              downloadInterval = setInterval(() => { checkCurrentDownload(response) }, 1000);
            }
          });
        }
      break;
      case "armband_labels":
        if (file.eventId) {
          apiService.requestEventArmbandLabels(file.eventId).then((response) => {
            if (response._id && downloadInterval === undefined) {
              downloadInterval = setInterval(() => { checkCurrentDownload(response) }, 1000);
            }
          });
        }
      break;
      case "armband":
        if (file.eventId) {
          apiService.requestRegistrationListForEvent(file.eventId).then((response) => {
            if (response._id && downloadInterval === undefined) {
              downloadInterval = setInterval(() => { checkCurrentDownload(response) }, 1000);
            }
          });
        }
      break;
      case "transactions":
        if (file.eventId) {
          apiService.requestEventTransactions(file.eventId).then((response) => {
            
            if (response._id && downloadInterval === undefined) {
              downloadInterval = setInterval(() => { checkCurrentDownload(response) }, 1000);
            }
          });
        }
      break;
      case "print_registration":
        if (file.eventId) {
          apiService.requestEntryFormsForEvent(file.eventId).then((response) => {
            if (response._id && downloadInterval === undefined) {
              downloadInterval = setInterval(() => { checkCurrentDownload(response) }, 1000);
            }
          });
        }
      break;
      case "checkin":
        if (file.eventId && file.showId) {
          let params: any = {};

          if (file.showElement && file.showElement !== "None") {
            params.show_element = file.showElement;
          }

          if (file.showLevel && file.showLevel !== "None") {
            params.level = file.showLevel;
          }

          if (file.showSection && file.showSection !== "None") {
            params.section = file.showSection;
          }

          apiService.requestShowCheckinLists(file.eventId, file.showId, params).then((response) => {
            if (response._id && downloadInterval === undefined) {
              downloadInterval = setInterval(() => { checkCurrentDownload(response) }, 1000);
            }
          });
        }
      break;
      case "judge_schedules":
        if (file.eventId) {
          apiService.requestJudgingSchedule(file.eventId).then((response) => {
            if (response._id && downloadInterval === undefined) {
              downloadInterval = setInterval(() => { checkCurrentDownload(response) }, 1000);
            }
          });
        }
      break;
      case "results":
        if (file.eventId && file.showId) {
          let params: any = {};

          if (file.showElement && file.showElement !== "None") {
            params.show_element = file.showElement;
          }

          if (file.showLevel && file.showLevel !== "None") {
            params.level = file.showLevel;
          }

          if (file.showSection && file.showSection !== "None") {
            params.section = file.showSection;
          }

          apiService.requestShowResultLists(file.eventId, file.showId, params).then((response) => {
            if (response._id && downloadInterval === undefined) {
              downloadInterval = setInterval(() => { checkCurrentDownload(response) }, 1000);
            }
          });
        }
      break;
      case "scoresheets":
        if (file.eventId && file.showId) {
          let params: any = {};

          if (file.showElement && file.showElement !== "None") {
            params.show_element = file.showElement;
          }

          if (file.showLevel && file.showLevel !== "None") {
            params.level = file.showLevel;
          }

          if (file.showSection && file.showSection !== "None") {
            params.section = file.showSection;
          }

          apiService.requestScoresheets(file.eventId, file.showId, params).then((response) => {
            if (response._id && downloadInterval === undefined) {
              downloadInterval = setInterval(() => { checkCurrentDownload(response) }, 1000);
            }
          });
        }
      break;
      case "akc_scoresheets":
        if (file.eventId) {
          apiService.requestEventScoresheets(file.eventId).then((response) => {
            if (response._id && downloadInterval === undefined) {
              downloadInterval = setInterval(() => { checkCurrentDownload(response) }, 1000);
            }
          });
        }
      break;
      case "ribbons":
        if (file.eventId && file.showId && (file.labelsToSkip !== undefined)) {
          let params: any = {
            labels_to_skip: file.labelsToSkip
          };

          if (file.showElement && file.showElement !== "None") {
            params.show_element = file.showElement;
          }

          if (file.showLevel && file.showLevel !== "None") {
            params.level = file.showLevel;
          }

          if (file.showSection && file.showSection !== "None") {
            params.section = file.showSection;
          }

          apiService.requestResultRibbonLabels(file.eventId, file.showId, params).then((response) => {
            if (response._id && downloadInterval === undefined) {
              downloadInterval = setInterval(() => { checkCurrentDownload(response) }, 1000);
            }
          });
        }
      break;
      case "trial":
        if (file.eventId && file.showId) {
          apiService.requestTrialReport(file.eventId, file.showId).then((response) => {
            if (response._id && downloadInterval === undefined) {
              downloadInterval = setInterval(() => { checkCurrentDownload(response) }, 1000);
            }
          })
        }
      break;
    }
  }

  const checkCurrentDownload = (current: any) => {
    apiService.getFormStatus(current._id).then((status) => {
      if (status.status === Constants.form_build_status.complete) {
        apiService.downloadForm(current._id);
        setIsDownloading(false);
        download.shiftDownload();
        setdownloadTimer(0);

        clearInterval(downloadInterval);
        downloadInterval = undefined;
      } else if (status.status === Constants.form_build_status.error) {
        toast.show({
          title: "Download",
          content: "Unable to start download. (" + status.message + ")",
          duration: 10000,
          type: "fail"
        });

        setIsDownloading(false);
        download.shiftDownload();
        setdownloadTimer(0);

        clearInterval(downloadInterval);
        downloadInterval = undefined;
      } else {
        setdownloadTimer(downloadTimer + 1);
      }
    });
  }

  useEffect(() => {
    if (props.files.length > 0 && !isDownloading) {
      if (!isShown) {
        toggle();
      }

      setIsDownloading(true);
      requestDownload(props.files[0]);
    } else if (props.files.length === 0 && !isDownloading && isShown) {
      toggle();
    }
  }, [isShown, props.files, toggle, isDownloading, requestDownload]);

  const getDownloadContent = () => {
    return(
      <div className="downloadWrap">
        <div className="title">Downloading Files</div>
        <div className="content">
          <div id="loading"></div>
          <div className="message">
            <span>Generating Files ({props.files.length} in queue)</span>
            {downloadTimer >= 5 && <span className="largeDownload">This is taking a while, you can close this window and your browser will start the download when it's ready.</span>}
          </div>
        </div>
      </div>
      );
  }

  return (<Modal className="Download" isShown={isShown} hide={toggle} modalContent={getDownloadContent()}  />);
};

export default Download;