import { Constants } from "@eagerdog/constants";

export const ShowTypes:any = {
  [Constants.sanctioning_club.UKC]: {
    [Constants.show_type.nosework]: {
      [Constants.dog_class_element.nosework_container]: [
        Constants.dog_class_element_level.novice,
        Constants.dog_class_element_level.advanced,
        Constants.dog_class_element_level.superior,
        Constants.dog_class_element_level.master,
        Constants.dog_class_element_level.elite
      ],
      [Constants.dog_class_element.nosework_exterior]: [
        Constants.dog_class_element_level.novice,
        Constants.dog_class_element_level.advanced,
        Constants.dog_class_element_level.superior,
        Constants.dog_class_element_level.master,
        Constants.dog_class_element_level.elite
      ],
      [Constants.dog_class_element.nosework_interior]: [
        Constants.dog_class_element_level.novice,
        Constants.dog_class_element_level.advanced,
        Constants.dog_class_element_level.superior,
        Constants.dog_class_element_level.master,
        Constants.dog_class_element_level.elite
      ],
      [Constants.dog_class_element.nosework_vehicle]: [
        Constants.dog_class_element_level.novice,
        Constants.dog_class_element_level.advanced,
        Constants.dog_class_element_level.superior,
        Constants.dog_class_element_level.master,
        Constants.dog_class_element_level.elite,
        Constants.dog_class_element_level.excellent
      ],
      [Constants.dog_class_element.nosework_handler_descrimination]: [
        Constants.dog_class_element_level.novice,
        Constants.dog_class_element_level.advanced,
        Constants.dog_class_element_level.excellent,
        Constants.dog_class_element_level.master
      ]
    },
    [Constants.show_type.conformation]: {
      [Constants.dog_class_element.conformation]: [
        Constants.dog_class_element_level.puppy,
        Constants.dog_class_element_level.junior,
        Constants.dog_class_element_level.adult,
        Constants.dog_class_element_level.open,
        Constants.dog_class_element_level.breader_handler,
        Constants.dog_class_element_level.champion,
        Constants.dog_class_element_level.grand_champion
      ],
      [Constants.dog_class_element.conformation_altered]: [
        Constants.dog_class_element_level.puppy,
        Constants.dog_class_element_level.junior,
        Constants.dog_class_element_level.adult,
        Constants.dog_class_element_level.open,
        Constants.dog_class_element_level.breader_handler,
        Constants.dog_class_element_level.champion,
        Constants.dog_class_element_level.grand_champion
      ],
      [Constants.dog_class_element.conformation_non_licensed]: [
        Constants.dog_class_element.novice_puppy,
        Constants.dog_class_element.veteran,
        Constants.dog_class_element.brood_bitch_stud_dog,
        Constants.dog_class_element.brace,
        Constants.dog_class_element.total_dog,
        Constants.dog_class_element.junior_showmanship,
        Constants.dog_class_element_level.total_junior
      ],
    },
    [Constants.show_type.obedience]: {
      [Constants.dog_class_element_level.pre_novice]: [],
      [Constants.dog_class_element_level.beginner_novice]: [],
      [Constants.dog_class_element_level.novice_a]: [],
      [Constants.dog_class_element_level.novice_b]: [],
      [Constants.dog_class_element_level.novice_c]: [],
      [Constants.dog_class_element_level.advance_novice]: [],
      [Constants.dog_class_element_level.open_a]: [],
      [Constants.dog_class_element_level.open_b]: [],
      [Constants.dog_class_element_level.advance_open]: [],
      [Constants.dog_class_element_level.utility_a]: [],
      [Constants.dog_class_element_level.utility_b]: []
    },
    [Constants.show_type.rally_obedience]: {
      [Constants.dog_class_element_level.ro1_a]: [],
      [Constants.dog_class_element_level.ro1_b]: [],
      [Constants.dog_class_element_level.ro1_c]: [],
      [Constants.dog_class_element_level.ro2_a]: [],
      [Constants.dog_class_element_level.ro2_b]: [],
      [Constants.dog_class_element_level.ro3_a]: [],
      [Constants.dog_class_element_level.ro3_b]: [],
      [Constants.dog_class_element_level.rom_a]: [],
      [Constants.dog_class_element_level.rom_b]: []
    },
    [Constants.show_type.agility]: {
      [Constants.dog_class_element.performance_agility]: [
        Constants.dog_class_element.performance_agility
      ]
    },
    [Constants.show_type.weight_pull]: {
      [Constants.dog_class_element.performance_weight_pull]: [
        Constants.dog_class_element.performance_weight_pull
      ]
    },
    [Constants.show_type.precision_coursing]: {
      [Constants.dog_class_element_level.novice]: [],
      [Constants.dog_class_element_level.advanced]: [],
      [Constants.dog_class_element_level.superior]: [],
      [Constants.dog_class_element_level.master]: [],
      [Constants.dog_class_element_level.elite]: [],
      [Constants.dog_class_element_level.dash]: [],
      [Constants.dog_class_element_level.steeplechase]: [],
      [Constants.dog_class_element_level.fun_run]: [],
      [Constants.dog_class_element_level.puppy_dash]: []
    },
    [Constants.show_type.shed_dog]: {
      [Constants.dog_class_element_level.working_shed_dog]: [],
      [Constants.dog_class_element_level.shed_dog_champion]: [],
      [Constants.dog_class_element_level.shed_dog_youth]: []
    }
  }, 
  [Constants.sanctioning_club.AKC]: {
    [Constants.show_type.conformation]: {
      [Constants.dog_class_element_level.akc_4_6_month_puppy]: [],
      [Constants.dog_class_element_level.akc_6_9_month_puppy]: [],
      [Constants.dog_class_element_level.akc_9_12_month_puppy]: [],
      [Constants.dog_class_element_level.akc_12_18_month_puppy]: [],
      [Constants.dog_class_element_level.akc_under_12_month_bred_by_exhibitor]: [],
      [Constants.dog_class_element_level.akc_adult_bred_by_exhibitor]: [],
      [Constants.dog_class_element_level.akc_amateur_owner_handler]: [],
      [Constants.dog_class_element_level.akc_american_bred]: [],
      [Constants.dog_class_element_level.akc_open]: [],
      [Constants.dog_class_element_level.akc_best_of_breed]: [],
      [Constants.dog_class_element_level.akc_veteran_8_10_years]: [],
      [Constants.dog_class_element_level.akc_veteran_10_12_years]: [],
      [Constants.dog_class_element_level.akc_veteran_13_plus_years]: [],
      [Constants.dog_class_element.conformation_non_licensed]: [
        Constants.dog_class_element.akc_sweepstakes,
        Constants.dog_class_element.akc_futurity
      ],
    },
    [Constants.show_type.obedience]: {
      [Constants.dog_class_element_level.beginner_novice_a]: [],
      [Constants.dog_class_element_level.beginner_novice_b]: [],
      [Constants.dog_class_element_level.preferred_novice]: [],
      [Constants.dog_class_element_level.graduate_novice]: [],
      [Constants.dog_class_element_level.novice_a]: [],
      [Constants.dog_class_element_level.novice_b]: [],
      [Constants.dog_class_element_level.open_a]: [],
      [Constants.dog_class_element_level.open_b]: [],
      [Constants.dog_class_element_level.preferred_open]: [],
      [Constants.dog_class_element_level.graduate_open]: [],
      [Constants.dog_class_element_level.utility_a]: [],
      [Constants.dog_class_element_level.utility_b]: [],
      [Constants.dog_class_element_level.preferred_utility]: [],
      [Constants.dog_class_element_level.versatility]: [],
      [Constants.dog_class_element_level.veteran]: [],
      [Constants.dog_class_element_level.brace]: [],
      [Constants.dog_class_element_level.team]: [],
      [Constants.dog_class_element_level.wc_novice]: [],
      [Constants.dog_class_element_level.wc_open]: [],
      [Constants.dog_class_element_level.wc_utility]: [],
      [Constants.dog_class_element_level.udx]: []
      // Constants.dog_class_element_level.abi_brace,
    },
    [Constants.show_type.rally_obedience]: {
      [Constants.dog_class_element_level.novice_a]: [],
      [Constants.dog_class_element_level.novice_b]: [],
      [Constants.dog_class_element_level.intermediate]: [],
      [Constants.dog_class_element_level.advanced_a]: [],
      [Constants.dog_class_element_level.advanced_b]: [],
      [Constants.dog_class_element_level.excellent_a]: [],
      [Constants.dog_class_element_level.excellent_b]: [],
      [Constants.dog_class_element_level.master]: [],
      [Constants.dog_class_element_level.choice]: [],
      [Constants.dog_class_element_level.pairs]: [],
      [Constants.dog_class_element_level.team]: [],
      [Constants.dog_class_element_level.rae]: [],
      [Constants.dog_class_element_level.raem]: []
    },
    [Constants.show_type.fast_cat]: {
      [Constants.dog_class_element.fast_cat]: [
        Constants.dog_class_element.fast_cat
      ]
    },
    [Constants.show_type.fetch]: {
      [Constants.dog_class_element_level.novice]: [],
      [Constants.dog_class_element_level.intermediate]: [],
      [Constants.dog_class_element_level.advanced]: [],
      [Constants.dog_class_element_level.retriever]: []
    },
    [Constants.show_type.scentwork]: {
      [Constants.dog_class_element.scentwork_container]: [
        Constants.dog_class_element_level.novice_a,
        Constants.dog_class_element_level.novice_b,
        Constants.dog_class_element_level.scentwork_advanced,
        Constants.dog_class_element_level.scentwork_excellent,
        Constants.dog_class_element_level.scentwork_master
      ],
      [Constants.dog_class_element.scentwork_interior]: [
        Constants.dog_class_element_level.novice_a,
        Constants.dog_class_element_level.novice_b,
        Constants.dog_class_element_level.scentwork_advanced,
        Constants.dog_class_element_level.scentwork_excellent,
        Constants.dog_class_element_level.scentwork_master
      ],
      [Constants.dog_class_element.scentwork_exterior]: [
        Constants.dog_class_element_level.novice_a,
        Constants.dog_class_element_level.novice_b,
        Constants.dog_class_element_level.scentwork_advanced,
        Constants.dog_class_element_level.scentwork_excellent,
        Constants.dog_class_element_level.scentwork_master
      ],
      [Constants.dog_class_element.scentwork_buried]: [
        Constants.dog_class_element_level.novice_a,
        Constants.dog_class_element_level.novice_b,
        Constants.dog_class_element_level.scentwork_advanced,
        Constants.dog_class_element_level.scentwork_excellent,
        Constants.dog_class_element_level.scentwork_master
      ],
      [Constants.dog_class_element.scentwork_handler_disc]: [
        Constants.dog_class_element_level.novice_a,
        Constants.dog_class_element_level.novice_b,
        Constants.dog_class_element_level.scentwork_advanced,
        Constants.dog_class_element_level.scentwork_excellent,
        Constants.dog_class_element_level.scentwork_master
      ],
      [Constants.dog_class_element.conformation_non_licensed]: [
        Constants.dog_class_element.scentwork_detective
      ]
    },
    [Constants.show_type.lure_coursing]: {
      [Constants.dog_class_element_level.akc_open]: [],
      [Constants.dog_class_element_level.special]: [],
      [Constants.dog_class_element_level.veteran]: [],
      [Constants.dog_class_element_level.junior_coursing]: [],
      [Constants.dog_class_element_level.qualified_coursing]: []
    }
  }, 
  [Constants.sanctioning_club.ABI]: {
    [Constants.show_type.conformation]: {
      [Constants.dog_class_element.conformation]: [
        Constants.dog_class_element_level.abi_6_9_months,
        Constants.dog_class_element_level.abi_9_12_months,
        Constants.dog_class_element_level.abi_12_18_months,
        Constants.dog_class_element_level.abi_bred_by_exibitor,
        Constants.dog_class_element_level.abi_open,
        Constants.dog_class_element_level.abi_best_of_breed,
        Constants.dog_class_element_level.abi_brace,
        Constants.dog_class_element_level.abi_veteran,
        Constants.dog_class_element_level.abi_jr_champion,
        Constants.dog_class_element_level.abi_champion,
        Constants.dog_class_element_level.abi_master_champion,
        Constants.dog_class_element_level.abi_grand_champion,
        Constants.dog_class_element_level.abi_supreme_champion,
        Constants.dog_class_element_level.abi_ultimate_champion
      ],
      [Constants.dog_class_element.conformation_altered]: [
        Constants.dog_class_element_level.abi_altered
      ],
      [Constants.dog_class_element.conformation_non_licensed]: [
        Constants.dog_class_element_level.abi_baby_puppy,
        Constants.dog_class_element.veteran,
        Constants.dog_class_element.brace
      ]
      // Constants.dog_class_element.conformation_altered,
      // Constants.dog_class_element.abi_best_of_breed,
      // Constants.dog_class_element.abi_bred_by_exibitor
    },
    [Constants.show_type.urban_rat_race]: {
      [Constants.dog_class_element.tubes]: [
        Constants.dog_class_element_level.rookie,
        Constants.dog_class_element_level.clever,
        Constants.dog_class_element_level.adventurer,
        Constants.dog_class_element_level.explorer,
        Constants.dog_class_element_level.detective,
        Constants.dog_class_element_level.inspector
      ],
      [Constants.dog_class_element.unobstructed]: [
        Constants.dog_class_element_level.rookie,
        Constants.dog_class_element_level.clever,
        Constants.dog_class_element_level.adventurer,
        Constants.dog_class_element_level.explorer,
        Constants.dog_class_element_level.detective,
        Constants.dog_class_element_level.inspector
      ],
      [Constants.dog_class_element.obstructed]: [
        Constants.dog_class_element_level.rookie,
        Constants.dog_class_element_level.clever,
        Constants.dog_class_element_level.adventurer,
        Constants.dog_class_element_level.explorer,
        Constants.dog_class_element_level.detective,
        Constants.dog_class_element_level.inspector
      ],
      [Constants.dog_class_element.fantastic_five]: [
        Constants.dog_class_element.fantastic_five
      ],
      [Constants.dog_class_element.tempting_ten]: [
        Constants.dog_class_element.tempting_ten
      ]
    },
    [Constants.show_type.country_rat_race]: {
      [Constants.show_type.country_rat_race]: [
        Constants.show_type.country_rat_race
      ]
    }
  }
};