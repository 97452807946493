import React, { useState, useEffect } from "react";
import { IEvent, IShow } from "@eagerdog/interfaces";

import { apiService } from "src/services/api.service";
import { helperService } from "src/services/helper.service";

import Input from "src/components/Input/Input";
import Button from "src/components/Button/Button";
import Chip from "src/components/Chip/Chip";
import { toast } from "src/components/Toast/ToastManager";

import styles from "./NotificationsTab.module.scss";

interface IProps {
  event: IEvent,
  show: IShow,
  onUpdate(): void
}

const NotificationsTab: React.FC<IProps> = (props) => {
  const [email, setEmail] = useState<string>("");
  const [list, setList] = useState<string[]>(props.show?.notification_emails ? props.show.notification_emails : []);
  const [render, setRender] = useState<boolean>(true);
  const [updatingShow, setUpdatingShow] = useState<boolean>(false);

  const addEmail = (email: string) => {
    if (helperService.isValidEmail(email)) {
      setList([...list, email]);
      setEmail("");
      setRender(false);
    } else {
      toast.show({
        title: "Show Notifications",
        content: "Not a valid email",
        duration: 10000,
        type: "fail",
      });
    }
  }

  const removeEmail = (index: number) => {
    let _list:string[] = [...list];

    _list.splice(index, 1);

    setList(_list);
  }

  const updateNotifList = () => {
    setUpdatingShow(true);

    let payload:any = {
      ...props.show,
      notification_emails: list
    };

    apiService.updateShow(props.event._id, props.show._id, payload).then((response) => {
      toast.show({
        title: "Show Settings",
        content: "You've successfully updated " + props.show.show_type + " " + props.show.show_name + "'s notification list",
        duration: 10000,
        type: "success"
      });

      props.onUpdate();
    }).finally(() => {
      setUpdatingShow(false);
    });
  }

  useEffect(() => {
    if (!render) {
      setRender(true);
    }
  }, [render]);

  return (
    <div className={styles.NotificationsTab}>
      <div className={styles.notifContent}>
        <p>Are there any emails you want to subscribe to notifications? These emails will be notified when a user registers to this show.</p>
        <div className={styles.notifForm}>
          <div className={styles.notifInput}>
            {render && <Input className={styles.notifInputActual} label="Notification Email" type="email" defaultValue={email} onChange={(e: any) => { e.stopPropagation(); setEmail(e.target.value); }} onKeyDown={(e: any) => { if (e.code === "Enter") { e.preventDefault(); addEmail(email); } }} placeholder="Enter an Email" />}
          </div>
          <Button className={styles.notifButton} onClick={(e: any) => { e.preventDefault(); addEmail(email); }}>Add</Button>
        </div>
        <div className={styles.notifList}>
          {list.map((email: string, index: number) => {
            return(<Chip key={index} onRemove={() => { removeEmail(index); }}>{email}</Chip>);
          })}
          {list.length === 0 && <div className={styles.emptyList}>No Emails Have been Added</div>}
        </div>
      </div>
      <div className={styles.notifActions}>
        <Button isLoading={updatingShow} onClick={() => { updateNotifList(); }}>Save Email List</Button>
      </div>
    </div>
  );
};

export default NotificationsTab;