import React from 'react';

import type { CustomCellRendererProps } from 'ag-grid-react';

import styles from "./WaitlistTab.module.scss";

export const StatusRenderer = (props: CustomCellRendererProps) => {
  return (<div className={styles.StatusRenderer}>
    <div className={`${styles.status} ${styles[props.value]}`}>{props.value}</div>
  </div>);
};