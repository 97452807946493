import React, { useState, useCallback, useEffect } from "react";
import { Constants } from "@eagerdog/constants";
import { IEvent, IShow, IRunningOrder, IRunningOrderDogClass } from "@eagerdog/interfaces";
import moment from "moment";

import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

import { apiService } from "src/services";

import Button from "src/components/Button/Button";
import { toast } from "src/components/Toast/ToastManager";

import styles from "./JudgeSchedulesTab.module.scss";

interface IProps {
  event: IEvent,
  show: IShow,
  onUpdate(): void
}

interface IRunningOrderDogClassDrag {
  index: number,
  show_element: string,
  level?: string,
  section?: string,
  amount: number
}

interface IRunningOrderDogClassDrag extends IRunningOrderDogClass {
  index: number,
  label?: string,
  group_with?: string
}

interface IRunningOrderDrag extends IRunningOrder {
  total_hours: number,
  dog_classes: IRunningOrderDogClassDrag[]
}

const JudgeSchedulesTab: React.FC<IProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  const elementTimes:any = {
    [Constants.sanctioning_club.AKC] : {
      [Constants.show_type.rally_obedience]: {
        [Constants.dog_class_element_level.novice_a]: Constants.running_order_times.AKC.rally_obedience.novice_a,
        [Constants.dog_class_element_level.novice_b]: Constants.running_order_times.AKC.rally_obedience.novice_b,
        [Constants.dog_class_element_level.intermediate]: Constants.running_order_times.AKC.rally_obedience.intermediate,
        [Constants.dog_class_element_level.advanced_a]: Constants.running_order_times.AKC.rally_obedience.advanced_a,
        [Constants.dog_class_element_level.advanced_b]: Constants.running_order_times.AKC.rally_obedience.advanced_b,
        [Constants.dog_class_element_level.excellent_a]: Constants.running_order_times.AKC.rally_obedience.excellent_a,
        [Constants.dog_class_element_level.excellent_b]: Constants.running_order_times.AKC.rally_obedience.excellent_b,
        [Constants.dog_class_element_level.master]: Constants.running_order_times.AKC.rally_obedience.master,
        [Constants.dog_class_element_level.choice]: Constants.running_order_times.AKC.rally_obedience.choice,
        [Constants.dog_class_element_level.pairs]: Constants.running_order_times.AKC.rally_obedience.pairs,
        [Constants.dog_class_element_level.team]: Constants.running_order_times.AKC.rally_obedience.team
      },
      [Constants.show_type.obedience]: {
        [Constants.dog_class_element_level.pre_novice]: Constants.running_order_times.AKC.obedience.pre_novice,
        [Constants.dog_class_element_level.beginner_novice]: Constants.running_order_times.AKC.obedience.beginner_novice,
        [Constants.dog_class_element_level.beginner_novice_a]: Constants.running_order_times.AKC.obedience.beginner_novice_a,
        [Constants.dog_class_element_level.beginner_novice_b]: Constants.running_order_times.AKC.obedience.beginner_novice_b,
        [Constants.dog_class_element_level.preferred_novice]: Constants.running_order_times.AKC.obedience.preferred_novice,
        [Constants.dog_class_element_level.novice_a]: Constants.running_order_times.AKC.obedience.novice_a,
        [Constants.dog_class_element_level.novice_b]: Constants.running_order_times.AKC.obedience.novice_b,
        [Constants.dog_class_element_level.novice_c]: Constants.running_order_times.AKC.obedience.novice_c,
        [Constants.dog_class_element_level.advance_novice]: Constants.running_order_times.AKC.obedience.advance_novice,
        [Constants.dog_class_element_level.graduate_novice]: Constants.running_order_times.AKC.obedience.graduate_novice,
        [Constants.dog_class_element_level.open_a]: Constants.running_order_times.AKC.obedience.open_a,
        [Constants.dog_class_element_level.open_b]: Constants.running_order_times.AKC.obedience.open_b,
        [Constants.dog_class_element_level.advance_open]: Constants.running_order_times.AKC.obedience.advance_open,
        [Constants.dog_class_element_level.preferred_open]: Constants.running_order_times.AKC.obedience.preferred_open,
        [Constants.dog_class_element_level.graduate_open]: Constants.running_order_times.AKC.obedience.graduate_open,
        [Constants.dog_class_element_level.utility_a]: Constants.running_order_times.AKC.obedience.utility_a,
        [Constants.dog_class_element_level.utility_b]: Constants.running_order_times.AKC.obedience.utility_b,
        [Constants.dog_class_element_level.preferred_utility]: Constants.running_order_times.AKC.obedience.preferred_utility,
        [Constants.dog_class_element_level.wc_novice]: Constants.running_order_times.AKC.obedience.wc_novice,
        [Constants.dog_class_element_level.wc_open]: Constants.running_order_times.AKC.obedience.wc_open,
        [Constants.dog_class_element_level.wc_utility]: Constants.running_order_times.AKC.obedience.wc_utility,
        [Constants.dog_class_element_level.udx]: Constants.running_order_times.AKC.obedience.udx
      }
    }
  };

  const [judges, setJudges] = useState<IRunningOrderDrag[]>([]);

  useEffect(() => {
    if (!loaded) {
      const getRunningOrder = () => {
        return new Promise<IRunningOrder[]>((resolve) => {
          apiService.getShowRunningOrderSummary(props.event._id, props.show._id).then((response) => {
            resolve(response);
          }).catch(() => {
            resolve([]);
          });
        });
      }

      const generateRunningOrder = (_ro: IRunningOrder[]) => {
        let index:number = 0;
        let _fe_running_order:IRunningOrderDrag[] = [];
        let grouped_dog_classes:IRunningOrderDogClassDrag[] = [];

        for (let r in _ro) {
          let _running_order_item:any = {..._ro[r], total_hours: 8 };

          for (let d in _running_order_item.dog_classes) {
            let dc:IRunningOrderDogClassDrag = _running_order_item.dog_classes[d];
            let grouped:boolean = false;

            /* GROUP HACK START - ED-610 */
            if (dc.group_with !== undefined) {
              grouped = true;
              grouped_dog_classes.push(dc);
            }

            if (grouped === false) {
              _running_order_item.dog_classes[d].index = index;
              index++;              
            }
            /* GROUP HACK END - ED-610 */
          }

          _fe_running_order.push(_running_order_item);
        }

        /* GROUP HACK START - ED-610 - REMOVE GROUPED DOG CLASSES */
        for (let fe in _fe_running_order) {
          for (let _dc in _fe_running_order[fe].dog_classes) {
            if (_fe_running_order[fe].dog_classes[_dc].group_with !== undefined) {
              _fe_running_order[fe].dog_classes.splice(Number(_dc), 1);
            }
          }
        }

        /* ADD GROUPED DOG CLASS TO MAIN DOG CLASS */
        for (let fe in _fe_running_order) {
          for (let _gdc in grouped_dog_classes) {
            let hasGroupLevel:IRunningOrderDogClassDrag[] = _fe_running_order[fe].dog_classes.filter((dc: any) => { return grouped_dog_classes[_gdc].group_with === dc.level; });

            if (hasGroupLevel.length > 0) {
              if (hasGroupLevel[0].entries) {
                hasGroupLevel[0].entries += grouped_dog_classes[_gdc].entries || 0;
              }

              hasGroupLevel[0].label = "(" + grouped_dog_classes[_gdc].level + ")";
            }
          }
        }
        /* GROUP HACK END - ED-610 */

        return _fe_running_order;
      }

      const loadJudgeRunningOrder = async() => {
        let runningOrder:IRunningOrder[] = await getRunningOrder();
        let FERunningOrder:IRunningOrderDrag[] = generateRunningOrder(runningOrder);

        setJudges(FERunningOrder);
      }

      loadJudgeRunningOrder();

      setLoaded(true);
    }
  }, [loaded, props.event._id, props.show._id]);

  const getIndexOffset = useCallback((_judges: IRunningOrderDrag[], judgeIndex: number) => {
    let offset:number = 0;

    if (judgeIndex > 0) {
      for (let j in judges) {
        if (Number(j) < judgeIndex) {
          offset += judges[j].dog_classes.length;
        }
      }
    }

    return offset;
  }, [judges]);

  const reorder = useCallback((_judges: IRunningOrderDrag[], startIndex: number, endIndex: number, judgeIndex: number) => {
    const offset:number = getIndexOffset(_judges, judgeIndex);

    const [removed] = _judges[judgeIndex].dog_classes.splice(startIndex - offset, 1);
    _judges[judgeIndex].dog_classes.splice(endIndex - offset, 0, removed);

    return _judges;
  }, [getIndexOffset]);

  const move = useCallback((_judges: IRunningOrderDrag[], source: any, destination: any) => {
    const offsetSource:number = getIndexOffset(_judges, Number(source.droppableId));
    const offsetDest:number = getIndexOffset(_judges, Number(destination.droppableId));

    const [removed] = _judges[source.droppableId].dog_classes.splice((source.index - offsetSource), 1);
    _judges[destination.droppableId].dog_classes.splice((destination.index - offsetDest), 0, removed);

    return _judges;
  }, [getIndexOffset]);

  const getShowElementIndex = (_show_element: IRunningOrderDogClassDrag) => {
    let index:number = 0;
    for (let j in judges) {
      for (let se in judges[j].dog_classes) {
        if (_show_element === judges[j].dog_classes[se]) {
          return index;
        }

        index++;
      }
    }

    return index;
  }

  const resetDraggableIndexes = (_judges: IRunningOrderDrag[]) => {
    let _newJudges:IRunningOrderDrag[] = Array.from(_judges);
    let index:number = 0;

    for (let j in _newJudges) {
      for (let se in _newJudges[j].dog_classes) {
        _newJudges[j].dog_classes[se].index = index;
        index++;
      }
    }

    return _newJudges;
  }

  const addBreak = (index:number) => {
    let _judges:any = Array.from(judges);

    _judges[index].dog_classes.push({
      index: 9999,
      type: Constants.running_order_dog_class_type.break
    });

    _judges = resetDraggableIndexes(_judges);

    setJudges(_judges);
  }

  const removeBreak = (jIndex: number, bIndex: number) => {
    let _judges:any = Array.from(judges);

    _judges[jIndex].dog_classes.splice(bIndex, 1)

    _judges = resetDraggableIndexes(_judges);

    setJudges(_judges);
  }

  const onDragEnd = useCallback((result: any) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    const _judges = Array.from(judges);

    const hasIndexedElement = _judges[source.droppableId].dog_classes.filter((dc: IRunningOrderDogClassDrag) => { return dc.index === source.index });
    let actualIndex:number = -1;

    if (hasIndexedElement.length > 0) {
      actualIndex = _judges[source.droppableId].dog_classes.indexOf(hasIndexedElement[0]);
    }

    if (sInd === dInd) {
      let newJudges = reorder(_judges, source.index, destination.index, sInd);
      setJudges(newJudges);
    } else {
      if (actualIndex !== -1 && _judges[source.droppableId].dog_classes[actualIndex].type !== Constants.running_order_dog_class_type.break) {
        let newJudges = move(_judges, source, destination);
        setJudges(newJudges);
      } else {
        toast.show({
          title: "Judge Breaks",
          content: "To add a break to a different judge, click 'Add Break' beside the judges total hours",
          duration: 10000,
          type: "fail"
        });
      }
    }
  }, [judges, move, reorder]);

  const getTotalJudgeHours = (judge: IRunningOrderDrag, upToIndex?: number) => {
    let total:number = 0;

    for (let se in judge.dog_classes) {
      let _showElementTime:number = Constants.running_order_times.break;

      if ((upToIndex === undefined) || (Number(se) < upToIndex)) {
        if (judge.dog_classes[se].type !== "break") {
          _showElementTime = (elementTimes[props.event.sanctioning_club][judge.dog_classes[se].show_element][judge.dog_classes[se].level || ""] || 3) * (judge.dog_classes[se].entries || 0);
        }

        total += _showElementTime;
      }
    }

    return Number((total / 60).toFixed(2));
  }

  const moveJudge = (index: number, direction:"left" | "right") => {
    let _judges:IRunningOrderDrag[] = Array.from(judges);
    let _j:IRunningOrderDrag = _judges[index];

    _judges.splice(index, 1);
    _judges.splice(direction === "right" ? index + 1 : index -1, 0, _j);

    setJudges(_judges);
  }

  const updateRunningOrder = () => {
    let _updatedShow:any = { ...props.show, running_order: judges };
    delete _updatedShow.__v;

    apiService.updateShow(props.event._id, props.show._id, _updatedShow).then((response) => {
      toast.show({
        title: "Judge Schedules",
        content: "You've successfully updated your judge schedules",
        duration: 10000,
        type: "success"
      });
    }).catch((error: any) => {
      toast.show({
        title: "Judge Schedules",
        content: error.response.data.message ? error.response.data.message : "Something went wrong, please try again later",
        duration: 10000,
        type: "fail"
      });
    });
  }

  const getJudgeTiming = (jIndex: number) => {
    let _showDate:Date = props.show.show_date;
    let _totalHours:number = 0;

    for (let j in judges) {
      if (Number(j) < jIndex) {
        _totalHours += getTotalJudgeHours(judges[j]);
      }
    }

    let hours:number = Math.floor(_totalHours);
    let minutes:number = (_totalHours - Math.floor(_totalHours)) * 60;

    return moment(_showDate).add('hours', hours).add('minutes', minutes).format("h:mmA");;
  }

  const getElementTiming = (jIndex: number, sIndex: number) => {
    const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let _showDate:Date = props.show.show_date;
    let _totalHours:number = 0;

    for (let j in judges) {
      if (Number(j) <= jIndex) {
        if (Number(j) === jIndex) {
          _totalHours += getTotalJudgeHours(judges[j], sIndex);
        } else {
          _totalHours += getTotalJudgeHours(judges[j]);
        }
        
      }
    }

    let hours:number = Math.floor(_totalHours);
    let minutes:number = (_totalHours - Math.floor(_totalHours)) * 60;

    return moment(_showDate).add('hours', hours).add('minutes', minutes).tz(timeZoneString).format("h:mmA");;
  }

  const getLevelContent = (jIndex: number, seIndex: number, se: IRunningOrderDogClassDrag) => {
    if (se.type === Constants.running_order_dog_class_type.break) {
      return (<>
        <div className={`${styles.ElementIconWrap} ${styles.Break}`}>
          <div className={`${styles.icon} ${styles.break}`}></div>
        </div>
        <div className={`${styles.ElementRight} ${styles.Break}`}>
          <div className={styles.ElementName}><span>Break</span><span onClick={() => { removeBreak(jIndex, seIndex); }}></span></div>
          <div className={styles.EntryAmount}>60 Minutes</div>
        </div>
      </>);
    } else {
      return(<>
        <div className={styles.ElementIconWrap}>
          <div className={`${styles.icon} ${styles.handle}`}></div>
        </div>
        <div className={styles.ElementRight}>
          <div className={styles.ElementName}><span>{se.show_element}</span>{se.level && <span>, {se.level}</span>} {se.label && <>{se.label}</>}</div>
          <div className={styles.EntryAmount}>{(se.entries || 0)} {((se.entries || 0) > 1 || (se.entries || 0) === 0) ? "Entries" : "Entry"} (Starts at {getElementTiming(jIndex, seIndex)})</div>
        </div>
      </>);
    }
  }

  return (
    <div className={styles.JudgeSchedulesTab}>
      {judges.length === 0 && <div className={styles.NoRunningOrder}>Couldn't find a running order. Running orders are generated on show edit and creation, you may need to edit your show to generate a running order.</div>}
      {judges.length > 0 && <DragDropContext
        onDragEnd={onDragEnd}
      >
        <div className={styles.JudgeSchedulesContainer}>
          <div className={styles.JudgeDroppables}>
            {judges.map((j: IRunningOrderDrag, jIndex: number) => {
              return (<Droppable key={jIndex} droppableId={`${jIndex}`}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} className={styles.JudgeDroppable} {...provided.droppableProps}>
                    <div className={styles.JudgeInfo}>
                      <div className={styles.JudgeName}>
                        <div className={styles.JudgeNameInner}>{j.judge_name} <span className={styles.judgeNum}>({j.judge_number})</span></div>
                      </div>
                      <div className={styles.JudgeStart}>
                        {jIndex !== 0 && <div className={`${styles.Caret} ${styles.Left}`} onClick={() =>{ moveJudge(jIndex, "left"); }}></div>}
                        <span>Judge {jIndex + 1} ({getJudgeTiming(jIndex)})</span>
                        {(jIndex < judges.length - 1) && <div className={`${styles.Caret} ${styles.Right}`} onClick={() =>{ moveJudge(jIndex, "right"); }}></div>}
                      </div>
                      <div className={(getTotalJudgeHours(j) > j.total_hours) ? `${styles.JudgeTime} ${styles.JudgeTimeOver}` : `${styles.JudgeTime}`}>
                        <span className={styles.JudgeTimeInner}>{getTotalJudgeHours(j)} / {j.total_hours} Hours</span>
                        <button className={styles.BreakButton} onClick={() =>{ addBreak(jIndex); }}>Add Break</button>
                      </div>
                    </div>
                    {j.dog_classes.map((se: IRunningOrderDogClassDrag, seIndex:  number) => {
                      return (<Draggable key={se.index} draggableId={se.index.toString()} index={getShowElementIndex(se)}>
                        {(provided, snapshot) => (
                          <div
                            className={styles.ElementDraggable}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {getLevelContent(jIndex, seIndex, se)}
                          </div>
                        )}
                      </Draggable>);
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>);
            })}
          </div>
          <div className={styles.JudgeActions}>
            <Button onClick={() => { updateRunningOrder(); }} className={styles.JudgeButton}>Save Judge Schedules</Button>
          </div>
        </div>
      </DragDropContext>}
    </div>
  );
};

export default JudgeSchedulesTab;